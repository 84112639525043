import React, { useEffect, useState } from 'react';
import Menu from '../../../../components/Menu';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import moment from 'moment';

export default function ListaCotacao() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        cotacao: firebase.firestore().collection('cotacao'),
    })
    const [mes, setMes] = useState(moment().format('MM'))
    const [ano, setAno] = useState(moment().format('YYYY'))
    const [tipo, setTipo] = useState('')
    const [usuario, setUsuario] = useState({})
    const [cotacoes, setCotacoes] = useState([])

    useEffect(() => {
        mensagemLoading('Carregando cotaçoes..')
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
                servicos
                    .cotacao
                    .where('mes', '==', mes)
                    .where('ano', '==', ano)
                    .get().then((res) => {
                        const data = res.docs.map((d) => d.data())
                        setCotacoes(data)
                        Swal.close()
                    })
            }
        })

    }, {})

    function filtro() {
        if (!mes) {
            mensagemAlerta('Por favor selecione o Mes')
            return
        }
        if (!ano) {
            mensagemAlerta('Por favor selecione o Ano')
            return
        }
        if (!tipo) {
            mensagemAlerta('Por favor selecione o Tipo')
            return
        }
        mensagemLoading('Carregando cotações..')
        if (tipo === 'ALL') {
            servicos
                .cotacao
                .where('mes', '==', mes)
                .where('ano', '==', ano)
                .get().then((res) => {
                    const data = res.docs.map((d) => d.data())
                    setCotacoes(data)
                    Swal.close()
                })
        } else {
            servicos
                .cotacao
                .where('mes', '==', mes)
                .where('ano', '==', ano)
                .where('tipo', '==', tipo)
                .get().then((res) => {
                    const data = res.docs.map((d) => d.data())
                    setCotacoes(data)
                    Swal.close()
                })
        }

    }

    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }
    const handleTipo = (event) => {
        event.persist();
        setTipo(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-info"><i className="bx bxs-file" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{cotacoes.filter((d) => d.status === 'PENDENTE').length}</h4>
                                                </div>
                                                <p className="mb-1">PENDENTE</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-danger h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-success"><i className="bx bx-file" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{cotacoes.filter((d) => d.status === 'FINALIZADO').length}</h4>
                                                </div>
                                                <p className="mb-1">FINALIZADO</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-info h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-danger"><i className="bx bx-file" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{cotacoes.filter((d) => d.status === 'CANCELADO').length}</h4>
                                                </div>
                                                <p className="mb-1">CANCELADO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <h5 className="card-header">Cotações do Período - {mes}/{ano}</h5>
                                    <div className="row col-md-12 container-xxl">
                                        <div class="mb-3 col-md-2">
                                            <label class="form-label" for="basic-icon-default-fullname">Mês</label>
                                            <select id="defaultSelect" className="form-select" onChange={handleMes} >
                                                <option>Selecione</option>
                                                <option value={'01'} selected={mes === '01' ? 'selected' : null}>Janeiro</option>
                                                <option value={'02'} selected={mes === '02' ? 'selected' : null}>Fevereiro</option>
                                                <option value={'03'} selected={mes === '03' ? 'selected' : null}>Março</option>
                                                <option value={'04'} selected={mes === '04' ? 'selected' : null}>Abril</option>
                                                <option value={'05'} selected={mes === '05' ? 'selected' : null}>Maio</option>
                                                <option value={'06'} selected={mes === '06' ? 'selected' : null}>Junho</option>
                                                <option value={'07'} selected={mes === '07' ? 'selected' : null}>Julho</option>
                                                <option value={'08'} selected={mes === '08' ? 'selected' : null}>Agosto</option>
                                                <option value={'09'} selected={mes === '09' ? 'selected' : null}>Setembro</option>
                                                <option value={'10'} selected={mes === '10' ? 'selected' : null}>Outubro</option>
                                                <option value={'11'} selected={mes === '11' ? 'selected' : null}>Novembro</option>
                                                <option value={'12'} selected={mes === '12' ? 'selected' : null}>Dezembro</option>
                                            </select>
                                        </div>
                                        <div class="mb-3 col-md-2">
                                            <label class="form-label" for="basic-icon-default-fullname">Ano</label>
                                            <select id="defaultSelect" className="form-select" onChange={handleAno}  >
                                                <option>Selecione</option>
                                                <option value={'2024'} selected={ano === '2024' ? 'selected' : null}>2024</option>
                                                <option value={'2025'} selected={ano === '2025' ? 'selected' : null}>2025</option>
                                                <option value={'2026'} selected={ano === '2026' ? 'selected' : null}>2026</option>
                                                <option value={'2027'} selected={ano === '2027' ? 'selected' : null}>2027</option>
                                                <option value={'2028'} selected={ano === '2028' ? 'selected' : null}>2028</option>
                                                <option value={'2029'} selected={ano === '2029' ? 'selected' : null}>2029</option>
                                                <option value={'2030'} selected={ano === '2030' ? 'selected' : null}>2030</option>
                                            </select>
                                        </div>
                                        <div class="mb-3 col-md-2">
                                            <label class="form-label" for="basic-icon-default-fullname">Tipo</label>
                                            <select id="defaultSelect" className="form-select" onChange={handleTipo}  >
                                                <option>Selecione</option>
                                                <option value={'AGENTE'} >Agente</option>
                                                <option value={'PUBLICO'} >Público</option>
                                                <option value={'ALL'} >Todas</option>
                                            </select>
                                        </div>
                                        <div style={{ marginTop: 30 }} class="mb-3 col-md-2">
                                            <button onClick={() => filtro()} type="submit" class="btn btn-info">Filtrar</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Cotação</th>
                                                    <th>Origem</th>
                                                    <th>Destino</th>
                                                    <th>Empresa</th>
                                                    <th>Valor</th>
                                                    <th>Status</th>
                                                    <th>Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {cotacoes.map((cotacao) =>
                                                    <tr>
                                                        <td>{cotacao.cotacao}</td>
                                                        <td>{cotacao.origem.localidade}-{cotacao.origem.uf}</td>
                                                        <td>{cotacao.destino.localidade}-{cotacao.destino.uf}</td>
                                                        <td>{cotacao.nomeEmpresa}</td>
                                                        <td>{cotacao.valorTotal}</td>
                                                        <td>
                                                            {cotacao.status === 'PENDENTE' ?
                                                                <span class="badge bg-label-warning me-1">Pendente</span>
                                                                : cotacao.status === 'ANDAMENTO' ?
                                                                    <span class="badge bg-label-info me-1">Em Andamento</span>
                                                                    : cotacao.status === 'FINALIZADO' ?
                                                                        <span class="badge bg-label-success me-1">Finalizado</span>
                                                                        :
                                                                        <span class="badge bg-label-danger me-1">Cancelado</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            <button onClick={() => window.location = '/cotacaoreio?d=' + cotacao.cpfCnpj + '&m=' + cotacao.nf} type="button" className="btn rounded-pill btn-icon btn-primary">
                                                                <span className="tf-icons bx bx-search" />
                                                            </button>

                                                        </td>
                                                    </tr>
                                                )}
                                                {cotacoes.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem cotações cadastrados</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}