import React, { useEffect, useState } from 'react';
import Menu from '../../../../components/Menu';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import InputMask from "react-input-mask";
import moment from 'moment';
import { encode } from 'js-base64';
import { cnpj } from 'cpf-cnpj-validator';
import { buscaEndereco } from '../../../../services/Localidades'
import CurrencyInput from 'react-currency-input';
import { login, postFrete } from '../../../../services/Brudam'

export default function CotacaoAgente() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('c'))

    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        cotacoes: firebase.firestore().collection('cotacao'),
    })
    const [usuario, setUsuario] = useState({})
    const [dadosCotacao, setDadosCotacao] = useState({})
    const [cubagem, setCubagem] = useState(0)
    const [pesoCubado, setPesoCubado] = useState(0)
    const [historico, setHistorico] = useState([])

    // Dados pessoais
    const [cnpjEmpresa, setCnpjEmpresa] = useState('')
    const [nomeEmpresa, setNomeEmpresa] = useState('')
    const [nomeRepresentante, setNomeRepresentante] = useState('')
    const [email, setEmail] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [dados, setDados] = useState({})

    // Origem e Destino
    const [cepOrigem, setCepOrigem] = useState('')
    const [cepDestino, setCepDestino] = useState('')
    const [dadosCepOrigem, setDadosCepOrigem] = useState({})
    const [dadosCepDestino, setDadosCepDestino] = useState({})

    // Volumes
    const [produtos, setProdutos] = useState([])
    const [index, setIndex] = useState(null)
    const [nome, setNome] = useState('')
    const [comprimento, setComprimento] = useState(0)
    const [largura, setLargura] = useState(0)
    const [altura, setAltura] = useState(0)
    const [qtd, setQtd] = useState(0)
    const [peso, setPeso] = useState(0)
    const [valor, setValor] = useState(0)

    // Serviço
    const [servico, setServico] = useState('')
    const [tabela, setTabela] = useState('')

    // Cotação
    const [dadosEnvioCotacao, setDadosEnvioCotacao] = useState({})
    const [cotacao, setCotacao] = useState({})

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
                servicos.cotacoes.doc(id).get().then((res) => {
                    setCotacao(res.data())
                    let cub = 0
                    let pesCub = 0
                    servicos.cotacoes.doc(id).collection('historicos').get().then((hist) => {
                        const data = hist.docs.map((d) => d.data())
                        setHistorico(data.sort((a,b)=>{
                            return moment(b.dataCadastro) - moment(a.dataCadastro)
                        }))
                    })
                    servicos.cotacoes.doc(id).collection('produtos').get().then((prod) => {
                        const data = prod.docs.map((d) => d.data())
                        setProdutos(data)
                        data.forEach((d) => {
                            cub = cub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom))
                            pesCub = pesCub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom) * 300) * d.qVol
                        })
                        setCubagem(parseFloat(cub))
                        setPesoCubado(parseFloat(pesCub))
                    })
                })
            }
        })

    }, [])


    function enviaCotacao() {
        if (!cnpj.isValid(cnpjEmpresa.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CNPJ inválido')
            return
        }
        if (!nomeEmpresa) {
            mensagemAlerta('Digite o nome da Empresa')
            return
        }
        if (!nomeRepresentante) {
            mensagemAlerta('Digite o nome do Representante da empresa')
            return
        }
        if (!whatsapp || whatsapp.replace(/[^a-z0-9]/gi, '').length < 11) {
            mensagemAlerta('Whatsapp incompleto! Digite o DDD + 9 + Número')
            return
        }
        if (!email || !email.includes('@')) {
            mensagemAlerta('Email inválido. Exemplo: email@email.com')
            return
        }
        if (!cepOrigem || cepOrigem.replace(/[^a-z0-9]/gi, '').length < 8) {
            mensagemAlerta('Digite o Cep de origem')
            return
        }
        if (!cepDestino || cepDestino.replace(/[^a-z0-9]/gi, '').length < 8) {
            mensagemAlerta('Digite o Cep de Destino')
            return
        }
        if (produtos.length == 0) {
            mensagemAlerta('Adicione ao menos um produto')
            return
        }
        if (!valor || valor == 0) {
            mensagemAlerta('Digite o valor da nota')
            return
        }
        if (!servico) {
            mensagemAlerta('Por favor escolha o serviço acima')
            return
        }
        let pesoBruto = 0
        let qtdVol = 0
        let pesoCubado = 0

        produtos.forEach((d) => {
            console.log(d.dAlt / 100)
            pesoBruto = parseFloat(pesoBruto) + parseFloat(d.pBru)
            qtdVol = parseInt(qtdVol) + parseInt(d.qVol)
            pesoCubado = parseFloat(pesoCubado) + ((parseFloat(d.dAlt / 100) * parseFloat(d.dLar / 100) * parseFloat(d.dCom / 100)) * 300) * parseInt(d.qVol)
        })
        const data = {
            nDocEmit: "27069620000133",
            nDocCli: "27069620000133",
            cOrigCalc: dadosCepOrigem.ibge,
            cDestCalc: dadosCepDestino.ibge,
            cServ: servico,
            pBru: parseFloat(pesoBruto),
            cTab: tabela,
            qVol: parseInt(qtdVol),
            vNF: parseFloat(valor),
            volumes: produtos
        }

        const dadosEnvio = {
            agente: usuario,
            cnpjEmpresa: cnpjEmpresa,
            nomeEmpresa: nomeEmpresa,
            nomeRepresentante: nomeRepresentante,
            email: email,
            whatsapp: whatsapp,
            origem: {
                cep: cepOrigem,
                logradouro: dadosCepOrigem.logradouro,
                bairro: dadosCepOrigem.bairro,
                localidade: dadosCepOrigem.localidade,
                uf: dadosCepOrigem.uf,
                ibge: dadosCepOrigem.ibge
            },
            destino: {
                cep: cepDestino,
                logradouro: dadosCepDestino.logradouro,
                bairro: dadosCepDestino.bairro,
                localidade: dadosCepDestino.localidade,
                uf: dadosCepDestino.uf,
                ibge: dadosCepDestino.ibge
            },
            servico: servico,
            tabela: tabela,
            valorNota: parseFloat(valor),
            status: 'CADASTRADO'
        }
        mensagemLoading('Gerando Cotação..')
        login().then((res) => {
            postFrete(res.data.access_key, data).then((doc) => {
                if (doc.message === 'OK') {
                    setDadosEnvioCotacao(data)
                    let val = 0
                    doc.data.forEach((d) => {
                        val = parseFloat(val) + parseFloat(d.vTotal)
                    })
                    if (val > 0) {
                        Swal.close();
                        const data = {
                            dados: doc.data.filter((d) => d.vPeso > 0),
                            frete: {
                                produtos: dados.volumes,
                                cepOrigem: dados.origem,
                                cepDestino: dados.destino,
                                servico: dados.servico
                            }
                        }
                        dadosEnvio.entrega = data.dados.filter((d) => d.vPeso > 0)[0].nDias
                        dadosEnvio.advalore = parseFloat(data.dados[0].vAdv)
                        dadosEnvio.icms = parseFloat(data.dados[0].vICMS)
                        dadosEnvio.gris = parseFloat(data.dados[0].vGris)
                        dadosEnvio.pedagio = parseFloat(data.dados[0].vPedagio)
                        dadosEnvio.valorTotal = parseFloat(data.dados[0].vTotal)
                        dadosEnvio.valorFrete = (parseFloat(data.dados[0].vTotal) - parseFloat(data.dados[0].vAdv) - parseFloat(data.dados[0].vGris) + parseFloat(data.dados[0].vICMS) - parseFloat(data.dados[0].vPedagio))
                        setDadosCotacao(dadosEnvio)
                        setCotacao(data)
                    } else {
                        mensagemAlerta(doc.message)
                    }
                } else {
                    mensagemAlerta(doc.message)
                }
            })
        })
    }

    function adicionaProduto() {
        if (!nome) {
            mensagemAlerta('Informe o nome do produto')
            return
        }
        if (!comprimento) {
            mensagemAlerta('Informe o comprimento do volume')
            return
        }
        if (!largura) {
            mensagemAlerta('Informe a largura do volume')
            return
        }
        if (!altura) {
            mensagemAlerta('Informe a altura do volume')
            return
        }
        if (!qtd) {
            mensagemAlerta('Informe a quantidade de volumes')
            return
        }
        if (!peso) {
            mensagemAlerta('Informe o peso total do volume')
            return
        }
        const list = []
        const dados = {
            nome: nome,
            dAlt: parseFloat(altura),
            dLar: parseFloat(largura),
            dCom: parseFloat(comprimento),
            pBru: parseFloat(peso),
            qVol: parseInt(qtd),
        }
        if (index !== null) {
            produtos[index].nome = nome
            produtos[index].dAlt = parseFloat(altura)
            produtos[index].dLar = parseFloat(largura)
            produtos[index].dCom = parseFloat(comprimento)
            produtos[index].pBru = parseFloat(peso)
            produtos[index].qVol = parseFloat(qtd)

            cancelaAlteracao()
        } else {
            list.push(
                ...produtos,
                dados
            )
            setNome('')
            setComprimento(0)
            setLargura(0)
            setAltura(0)
            setQtd(0)
            setPeso(0)
            setProdutos(list)
        }

    }

    function removeProduto(p, index) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o produto ' + p.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const list = []
                for (let i = 0; i < produtos.length; i++) {
                    const element = produtos[i];
                    if (i !== index) {
                        list.push(element)
                    }
                }
                cancelaAlteracao()
                setProdutos(list)
            }
        });

    }

    function editaProduto(item, ind) {
        console.log(item.dCom)
        setIndex(ind)
        setComprimento(item.dCom)
        setLargura(item.dLar)
        setAltura(item.dAlt)
        setPeso(item.pBru)
        setQtd(item.qVol)
        setNome(item.nome)
    }

    function cancelaAlteracao() {
        setIndex(null)
        setNome('')
        setComprimento(0)
        setLargura(0)
        setAltura(0)
        setQtd(0)
        setPeso(0)
    }

    function setaServico(serv, tab) {
        setServico(serv)
        setTabela(tab)
    }

    // Dados Pessoais
    const handleCnpjEmpresa = (event) => {
        event.persist();
        setCnpjEmpresa(event.target.value);
    }
    const handleNomeEmpresa = (event) => {
        event.persist();
        setNomeEmpresa(event.target.value);
    }
    const handleNomeRepresentante = (event) => {
        event.persist();
        setNomeRepresentante(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleWhatsapp = (event) => {
        event.persist();
        setWhatsapp(event.target.value);
    }

    // Endereço
    const handleCepOrigem = (event) => {
        event.persist();
        setDadosCepOrigem({})
        setCepOrigem(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Carregando dados..')
            setDadosCepOrigem({})
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    Swal.close()
                    mensagemAlerta('Endereço não localizado')
                } else {
                    Swal.close()
                    setDadosCepOrigem(dados)
                }
            })
        }
    }
    const handleCepDestino = (event) => {
        event.persist();
        setDadosCepDestino({})
        setCepDestino(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Carregando dados..')
            setDadosCepDestino({})
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    Swal.close()
                    mensagemAlerta('Endereço não localizado')
                } else {
                    Swal.close()
                    setDadosCepDestino(dados)
                }
            })
        }
    }

    function enviarCotacao() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja enviar a cotação para Entrega Já?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Enviando a cotação..')
                console.log(dados)
                servicos.cotacoes.get().then((q) => {
                    servicos.cotacoes.add({
                        agente: {
                            id: usuario.id,
                            email: usuario.email,
                            agente: usuario.agente
                        },
                        nome: nomeEmpresa,
                        whatsapp: whatsapp,
                        email: email,
                        cnpj: cnpjEmpresa,
                        representante: nomeRepresentante,
                        origem: dadosCepOrigem,
                        destino: dadosCepDestino,
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        mes: moment().format('MM'),
                        ano: moment().format('YYYY'),
                        entrega: cotacao.dados[0].nDias,
                        servico: servico,
                        tabela: tabela,
                        advalore: parseFloat(cotacao.dados[0].vAdv),
                        icms: parseFloat(cotacao.dados[0].vICMS),
                        gris: parseFloat(cotacao.dados[0].vGris),
                        pedagio: parseFloat(cotacao.dados[0].vPedagio),
                        valorTotal: parseFloat(cotacao.dados[0].vTotal),
                        valorFrete: (parseFloat(cotacao.dados[0].vTotal) - parseFloat(cotacao.dados[0].vAdv) - parseFloat(cotacao.dados[0].vGris) + parseFloat(cotacao.dados[0].vICMS) - parseFloat(cotacao.dados[0].vPedagio)),
                        comissao: parseInt(usuario.comissao),
                        status: 'PENDENTE',
                        cotacao: 'COT' + (q.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY')
                    }).then((snap) => {
                        servicos.cotacoes.doc(snap.id).update({
                            id: snap.id
                        }).then(() => {
                            produtos.forEach((d) => {
                                servicos.cotacoes.doc(snap.id).collection('produtos').add(d)
                            })
                            mensagemSucesso('Cotação enviado com sucesso para Entrega Já')
                        })
                    }).catch((er) => {
                        mensagemErro('Erro ao cadastrar cotação ' + er)
                    })
                }).catch((er) => {
                    mensagemErro('Erro ao cadastrar cotação ' + er)
                })
            }
        });
    }

    // Dados do Volume
    const handleProduto = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    function handleComprimento(event, maskedvalue, floatvalue) {
        setComprimento(maskedvalue);

    }
    function handleLargura(event, maskedvalue, floatvalue) {
        setLargura(maskedvalue);
    }
    function handleAltura(event, maskedvalue, floatvalue) {
        setAltura(maskedvalue);
    }
    const handlePeso = (event) => {
        event.persist();
        setPeso(event.target.value);
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value);
    }
    function handleValor(event, maskedvalue, floatvalue) {
        setValor(maskedvalue)
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/agente'
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Error', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">

                                <div className="row">
                                    {cotacao.id ?
                                        <div className="col-md-12">
                                            <div className="card mb-4">
                                                <h5 className="card-header">Status da Cotação</h5>
                                                <div className="card-body">
                                                    <div className="mb-3 col-12 mb-0">
                                                        {cotacao.status === 'PENDENTE' ?
                                                            <div className="alert alert-warning">
                                                                <h6 className="alert-heading mb-1">Cotação pendente</h6>
                                                                <p className="mb-0">Sua cotação ainda está pendente de análise</p>
                                                            </div>
                                                            : cotacao.status === 'FINALIZADO' ?
                                                                <div className="alert alert-success">
                                                                    <h6 className="alert-heading mb-1">Cotação Confirmada</h6>
                                                                    <p className="mb-0">Sua cotação foi confirmada e está no processo logístico</p>
                                                                </div>
                                                                :
                                                                <div className="alert alert-danger">
                                                                    <h6 className="alert-heading mb-1">Cotação Cancelada</h6>
                                                                    <p className="mb-0">{cotacao.motivo}</p>
                                                                </div>

                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mb-4">
                                                <h5 className="card-header">Cotação #{cotacao.cotacao}</h5>
                                                {/* Account */}
                                                <div className="card-body row">
                                                    <div className="d-flex align-items-start align-items-sm-center gap-4 col-md-2">
                                                        <img src={usuario.id && usuario.avatarEmpresa ? usuario.avatarEmpresa : 'https://png.pngtree.com/png-vector/20190930/ourmid/pngtree-building-icon-isolated-on-abstract-background-png-image_1763153.jpg'} alt="user-avatar" className="d-block rounded" height={100} width={100} id="uploadedAvatar" />
                                                    </div>
                                                    <div className=" align-items-start align-items-sm-center gap-4 col-md-4">
                                                        <h3>{usuario.agente}</h3>
                                                        <p>{usuario.nomeEmpresa} <br />{usuario.cnpj}</p>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                                <div className="card-body">
                                                    <div onsubmit="return false">
                                                        <div className="row">
                                                            <div class="mb-3 col-md-12" >
                                                                <h5>Dados da Empresa</h5>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">CNPJ</label>
                                                                <p>{cotacao.empresa.cnpj}</p>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label class="form-label" for="basic-icon-default-fullname">Nome da Empresa</label>
                                                                <p>{cotacao.empresa.nome}</p>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label class="form-label" for="basic-icon-default-fullname">Nome do Representante</label>
                                                                <p>{cotacao.empresa.representante}</p>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">Whatsapp</label>
                                                                <p>{cotacao.empresa.whatsapp}</p>
                                                            </div>
                                                            <div class="mb-3 col-md-5">
                                                                <label class="form-label" for="basic-icon-default-fullname">Email</label>
                                                                <p>{cotacao.empresa.email}</p>
                                                            </div>

                                                        </div>
                                                        <hr className="my-4 " />
                                                        <div className="row" style={{ marginTop: 20 }}>
                                                            <div class="mb-3 col-md-12" >
                                                                <h5>Origem e Destino</h5>
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <h5 style={{ marginTop: 20 }} class="mb-0">Origem</h5>
                                                            </div>
                                                            <p>
                                                                {cotacao.origem.logradouro}, {cotacao.origem.bairro}<br />
                                                                {cotacao.origem.localidade} - {cotacao.origem.uf} - CEP {cotacao.origem.cep}<br />
                                                                {cotacao.origem.complemento} - {cotacao.origem.referencia}

                                                            </p>
                                                            <div class="mb-3 col-md-12">
                                                                <h5 style={{ marginTop: 20 }} class="mb-0">Destino</h5>
                                                            </div>
                                                            <p>
                                                                {cotacao.destino.logradouro}, {cotacao.destino.bairro}<br />
                                                                {cotacao.destino.localidade} - {cotacao.destino.uf} - CEP {cotacao.destino.cep}<br />
                                                                {cotacao.destino.complemento} - {cotacao.destino.referencia}

                                                            </p>

                                                        </div>
                                                        <hr className="my-4 " />
                                                        <div className="row" style={{ marginTop: 20 }}>
                                                            <div class="mb-3 col-md-12" >
                                                                <h5>Volumes</h5>
                                                            </div>
                                                            <div className='mb-3 col-md-12'>
                                                                <table class="table" style={{ marginTop: 20 }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Produto</th>
                                                                            <th>medidas(Compr x Larg x Alt)</th>
                                                                            <th>Peso</th>
                                                                            <th>Qtd</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="table-border-bottom-0">
                                                                        {produtos.map((p, index) =>
                                                                            <tr>
                                                                                <td>{p.nome}</td>
                                                                                <td>{parseInt(p.dCom * 100)} cm {parseInt(p.dLar * 100)} cm x {parseInt(p.dAlt * 100)} cm </td>
                                                                                <td>{p.pBru} kg</td>
                                                                                <td>{p.qVol}</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                                <label >Cubagem</label>
                                                                <p>{cubagem.toFixed(3)} m³</p>
                                                            </div>
                                                            <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                                <label >Peso Cubado</label>
                                                                <p>{pesoCubado.toFixed(2)} kg</p>
                                                            </div>

                                                        </div>
                                                        <hr className="my-4 " />
                                                        <div className="row" style={{ marginTop: 20 }}>
                                                            <div class="mb-3 col-md-12" >
                                                                <h5>Serviços e valores</h5>
                                                            </div>
                                                            {cotacao.servico == '080' ?
                                                                <div className='col-md-3'>
                                                                    <label style={{ marginTop: 20 }}>Serviço</label>
                                                                    <p><b>Entrega Convencional</b></p>
                                                                </div>
                                                                : cotacao.servico == '081' ?
                                                                    <>
                                                                        <div className='col-md-4'>
                                                                            <label style={{ marginTop: 20 }}>Serviço</label>
                                                                            <p><b>Entrega Convencional</b></p>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            <div className='col-md-3'>
                                                                <label style={{ marginTop: 20 }}>Valor da Nota</label>
                                                                <p><b>{(parseFloat(cotacao.valorNota)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></p>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <label style={{ marginTop: 20 }}>Pagamento Frete</label>
                                                                <p><b>{cotacao.pagamentoFrete === 'ORIGEM' ? 'Pagamento na Origem' : 'Pagamento na Entrega'}</b></p>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <label style={{ marginTop: 20 }}>Entrega</label>
                                                                <p><b>{cotacao.entrega === 'BASE' ? 'Retirada na Base' : 'Entrega a Domicílio'}</b></p>
                                                            </div>
                                                        </div>

                                                        {cotacao.dados ?
                                                            <>
                                                                <hr className="my-4 " />
                                                                <div className="row" style={{ marginTop: 20 }}>
                                                                    <div class="mb-3 col-md-12" >
                                                                        <h5>Resultado</h5>
                                                                    </div>
                                                                    <div class="mb-3 col-md-12">
                                                                        <hr />
                                                                        <h5 style={{ marginTop: 20 }} class="mb-0"><b>Resultado da Cotação</b></h5>
                                                                    </div>
                                                                    <div style={{ marginTop: 20 }} className='col-md-9'>
                                                                        Prazo Previsto de Entrega
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        De {cotacao.dados ? cotacao.dados[0].nDias - 2 : 0} á {cotacao.dados ? cotacao.dados[0].nDias : 0} dias úteis
                                                                    </div>
                                                                    <div style={{ marginTop: 30 }} className='col-md-9'>
                                                                        <b style={{ fontSize: 20 }}>Valor Total do Frete</b>
                                                                    </div>
                                                                    <div style={{ marginTop: 20 }} className='col-md-3'>
                                                                        <b style={{ fontSize: 30 }}>{(parseFloat(cotacao.dados[0].vTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b>
                                                                    </div>
                                                                    <div style={{ marginTop: 20 }} className='col-md-9'>
                                                                        <b style={{ fontSize: 20 }}>Valor Comissão</b>
                                                                    </div>
                                                                    <div style={{ marginTop: 10 }} className='col-md-3'>
                                                                        <b style={{ fontSize: 30 }}>{((parseFloat(cotacao.dados[0].vTotal) * parseInt(usuario.comissao) / 100)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b>
                                                                    </div>
                                                                    <div style={{ marginTop: 20 }} className='col-md-12'>
                                                                        <label><b>Observação</b></label>
                                                                        <p>Informamos que o valor indicado nesta cotação é uma estimativa, baseada nas informações preliminares fornecidas, como cubagem, peso e local de entrega. Destacamos que a taxa de coleta, assim como outros detalhes da cotação, deverá ser confirmada diretamente com um de nossos consultores no momento da geração da cotação. Estamos à disposição para esclarecer dúvidas e oferecer suporte em todas as etapas do processo.</p>
                                                                    </div>

                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                                {/* /Account */}
                                            </div>
                                            <div className="card mb-4">
                                                <h5 className="card-header">Histórico</h5>
                                                <div className="card-body">
                                                    <div className="mb-3 col-12 mb-0">
                                                        <table class="table" style={{ marginTop: 20 }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Ação</th>
                                                                    <th>Descrição</th>
                                                                    <th>Status</th>
                                                                    <th>Data</th>
                                                                    <th>Usuário</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="table-border-bottom-0">
                                                                {historico.map((p, index) =>
                                                                    <tr key={p.acap}>
                                                                        <td>{p.acao}</td>
                                                                        <td>{p.descricao}</td>
                                                                        <td>{p.status}</td>
                                                                        <td>{moment(p.dataCadastro).format('DD/MM/YYYY - HH:mm')}</td>
                                                                        <td>{p.usuario.nome}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}