import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import { login, getNota } from '../../services/Brudam'
import firebase from 'firebase';
import moment from 'moment';

export default function Agente() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        cotacao: firebase.firestore().collection('cotacao'),
    })
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [minuta, setMinuta] = useState('')
    const [usuario, setUsuario] = useState({})
    const [rastreios, setRastreios] = useState([])
    const [cotacoes, setCotacoes] = useState([])

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                mensagemLoading('Carregando informações')
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                    Swal.close()
                }).catch(()=>{
                    mensagemErro('Erro ao carregar informações')
                    Swal.close()
                })
                servicos.cotacao.where('agente.id', '==', user.uid).get().then((res) => {
                    const data = res.docs.map((d) => d.data())
                    setCotacoes(data)
                })
            }
        })

    }, {})

    function buscaMinuta() {
        if (!cpfCnpj && !minuta) {
            mensagemAlerta("O CPF/CNPJ e Nota Fiscal não podem ser vazios")
        } else {
            login().then((res) => {
                console.log(res.data.access_key)
                getNota(res.data.access_key, minuta, cpfCnpj).then((doc) => {
                    if (doc.message === 'OK') {
                        if (doc.data[0].dados) {
                            window.location = '/rastreio?d=' + cpfCnpj + '&m=' + minuta
                        } else {
                            mensagemAlerta('Sem registros encontrados')
                        }
                    } else {
                        mensagemAlerta('Sem registros encontrados')
                    }
                })

            })
        }
    }

    const handleCpfCnpj = (event) => {
        event.persist();
        setCpfCnpj(event.target.value);
    }
    const handleMinua = (event) => {
        event.persist();
        setMinuta(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="modal fade" id="modalCadastro" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="exampleModalLabel2">Por que se cadastrar em nossa base de rastreio?</h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body">
                                            <h6>Veja vantagens de está conectado com a Entrega Já</h6>
                                            <p>1. Voce pode salvar suas consultas e ver um histórico de suas compras com as empresas que são nossas parceiras</p>
                                            <p>2. Você não terá que colocar todas as vezes as suas mesmas informações para rastrear suas encomendas. Apenas seu email e senha será o suficiente</p>
                                            <p>3. Mais detalhamento de suas consultas, assim voce consigará ver toda a trajetória de suas encomendas com um layout simples e intuitivo. </p>
                                            <p>4. Caso você tenha mais de uma encomenda a rastrear com a Entrega Já, você poderá ter uma visão de qual produto chegará primeiro. Usando nossa inteligência. </p>
                                            <p><b>Gostou? Que tal se cadastrar com a Entrega Já? </b></p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                                                Apenas continuar com minha consulta
                                            </button>
                                            <button type="button" data-bs-dismiss="modal" onClick={() => window.location = '/cadastro'} className="btn btn-primary">Quero me cadastrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="smallModal" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog modal-sm" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel2">Suas informações </h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col mb-3">
                                                    <label htmlFor="nameSmall" className="form-label">CPF/CNPJ</label>
                                                    <input type="text" onChange={handleCpfCnpj} value={cpfCnpj} id="nameSmall" className="form-control" placeholder="Somente números" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-3">
                                                    <label htmlFor="nameSmall" className="form-label">Nota Fiscal</label>
                                                    <input type="text" onChange={handleMinua} value={minuta} id="nameSmall" className="form-control" placeholder="Nota fiscal da encomenda" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" data-bs-dismiss="modal" onClick={() => buscaMinuta()} className="btn btn-primary">Rastrear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-lg-12 mb-4 order-0">
                                        <div className="card">
                                            <div className="d-flex align-items-end row">
                                                <div className="col-sm-7">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-primary">Olá {usuario.agente} 🎉</h5>
                                                        <p className="mb-4">
                                                            Empresa <span className="fw-bold">{usuario.nomeEmpresa} ( {usuario.cnpj} )</span>
                                                        </p>
                                                        {/* {rastreios.length > 0 ?
                                                            <p className="mb-4">
                                                                Você possui <span className="fw-bold">{rastreios.length}</span> encomendas rastreadas pela Entrega Já
                                                            </p>
                                                            :
                                                            <p className="mb-4">
                                                                <span className="fw-bold"> Você não possui encomendas rastreadas pela Entrega Já</span>
                                                            </p>
                                                        } */}
                                                        {usuario.id && usuario.status === 'ATIVO' ?
                                                            <a href="/cotacaoAgente" className="btn btn-sm btn-outline-primary">Nova Cotação</a>
                                                            :
                                                            null
                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-sm-5 text-center text-sm-left">
                                                    <div className="card-body pb-0 px-0 px-md-4">
                                                        <img src="../assets/img/illustrations/man-with-laptop-light.png" height={140} alt="View Badge User" data-app-dark-img="illustrations/man-with-laptop-dark.png" data-app-light-img="illustrations/man-with-laptop-light.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {usuario.id && usuario.status === 'PENDENTE' ?
                                <div className="container-xxl ">
                                    <div className="row">
                                        <div className="col-lg-12 mb-4 order-0">
                                            <div className="card">
                                                <div className="d-flex align-items-end row">
                                                    <div className="col-sm-12">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-primary">Cadastro em análise</h5>

                                                            <p className="mb-12" >
                                                                <b>Estamos quase lá! 👋 Seu cadastro está sob os holofotes da nossa equipe de consultores, que está dedicando atenção especial à análise das suas informações. Fique ligado! Em breve, você poderá enviar links de cotação diretamente aos seus clientes, realizar cotações em tempo real e acompanhar todas as suas transações com a Entrega Já neste portal. Estamos ansiosos para ver você em ação!</b>
                                                            </p>
                                                            <p>Equipe Entrega Já</p>
                                                            {/* <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalCadastro" className="btn btn-sm btn-outline-primary">Saiba Mais</a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container-xxl flex-grow-1 ">
                                    <div className="card">
                                        <h5 className="card-header">Suas Cotações</h5>
                                        <div className="table-responsive text-nowrap">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Cotação</th>
                                                        <th>Origem</th>
                                                        <th>Destino</th>
                                                        <th>Empresa</th>
                                                        <th>Valor</th>
                                                        <th>Comissão</th>
                                                        <th>Status</th>
                                                        <th>Ação</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-border-bottom-0">
                                                    {cotacoes.map((cotacao) =>
                                                        <tr>
                                                            <td>{cotacao.cotacao}</td>
                                                            <td>{cotacao.origem.localidade}-{cotacao.origem.uf}</td>
                                                            <td>{cotacao.destino.localidade}-{cotacao.destino.uf}</td>
                                                            <td>{cotacao.empresa.nome}</td>
                                                            <td>{cotacao.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                            <td>{((parseFloat(cotacao.valorTotal) * parseInt(usuario.comissao) / 100)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                            <td>
                                                                {cotacao.status === 'PENDENTE' ?
                                                                    <span class="badge bg-label-warning me-1">Pendente</span>
                                                                    : cotacao.status === 'ANDAMENTO' ?
                                                                        <span class="badge bg-label-info me-1">Em Andamento</span>
                                                                        : cotacao.status === 'FINALIZADO' ?
                                                                            <span class="badge bg-label-success me-1">Finalizado</span>
                                                                            :
                                                                            <span class="badge bg-label-danger me-1">Cancelado</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                <button onClick={() => window.location = '/detalheCotacaoAgente?c=' + cotacao.id} type="button" className="btn rounded-pill btn-icon">
                                                                    <span className="tf-icons bx bx-search" />
                                                                </button>

                                                            </td>
                                                        </tr>
                                                    )}
                                                    {cotacoes.length === 0 ?
                                                        <p style={{ textAlign: 'center', padding: 10 }} >Sem cotações cadastrados</p>
                                                        :
                                                        null
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            }

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}