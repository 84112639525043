import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { login, postFrete } from '../../../services/Brudam'
import { criaEmpresa, geraCotacaoNegocio, geraTask, atualizaCotacaoNegocio } from '../../../services/Agendor'
import firebase from 'firebase';
import moment from 'moment';

export default function Login() {
    const [servicos] = useState({
        cotacao: firebase.firestore().collection('cotacao'),
        mail: firebase.firestore().collection('mail'),
        monitoramento: firebase.firestore().collection('monitoramento'),
    })
    // Cotação
    const [idCotacao, setIdCotacao] = useState('')
    const [dados, setDados] = useState({})
    const [dadosEnvioCotacao, setDadosEnvioCotacao] = useState({})
    const [cotacao, setCotacao] = useState({})
    const [cubagem, setCubagem] = useState(0)
    const [pesoCubado, setPesoCubado] = useState(0)
    const [trecho, setTrecho] = useState(false)
    const [loading, setLoading] = useState(false)

    const [textoEmail] = useState(`<!DOCTYPE html>
    <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
    
    <head>
        <title></title>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!-->
        <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" type="text/css">
        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet" type="text/css"><!--<![endif]-->
        <style>
            * {
                box-sizing: border-box;
            }
    
            body {
                margin: 0;
                padding: 0;
            }
    
            a[x-apple-data-detectors] {
                color: inherit !important;
                text-decoration: inherit !important;
            }
    
            #MessageViewBody a {
                color: inherit;
                text-decoration: none;
            }
    
            p {
                line-height: inherit
            }
    
            .desktop_hide,
            .desktop_hide table {
                mso-hide: all;
                display: none;
                max-height: 0px;
                overflow: hidden;
            }
    
            .image_block img+div {
                display: none;
            }
    
            @media (max-width:720px) {
    
                .desktop_hide table.icons-inner,
                .social_block.desktop_hide .social-table {
                    display: inline-block !important;
                }
    
                .icons-inner {
                    text-align: center;
                }
    
                .icons-inner td {
                    margin: 0 auto;
                }
    
                .mobile_hide {
                    display: none;
                }
    
                .row-content {
                    width: 100% !important;
                }
    
                .stack .column {
                    width: 100%;
                    display: block;
                }
    
                .mobile_hide {
                    min-height: 0;
                    max-height: 0;
                    max-width: 0;
                    overflow: hidden;
                    font-size: 0px;
                }
    
                .desktop_hide,
                .desktop_hide table {
                    display: table !important;
                    max-height: none !important;
                }
            }
        </style>
    </head>
    
    <body style="margin: 0; background-color: #FFFFFF; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
        <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF;">
            <tbody>
                <tr>
                    <td>
                        <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <div class="spacer_block block-1" style="height:30px;line-height:30px;font-size:1px;">&#8202;</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #333; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 10px; padding-left: 20px; padding-right: 20px; padding-top: 10px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                            <tr>
                                                                <td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
                                                                    <div class="alignment" align="center" style="line-height:10px">
                                                                        <div style="max-width: 310px;"><img src="https://cotacao.entregaja.com.br/images/logo.png" style="display: block; height: auto; border: 0; width: 100%;" width="310" alt="Image" title="Image"></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div></div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #F4F4F4; background-image: url('https://d1oco4z2z1fhwp.cloudfront.net/templates/default/286/bg_wave_1.png'); background-position: top center; background-repeat: repeat;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div></div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #F4F4F4;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <table class="paragraph_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                            <tr>
                                                                <td class="pad" style="padding-left:30px;padding-right:30px;padding-top:10px;">
                                                                    <div style="color:#555555;font-family:'Montserrat','Trebuchet MS','Lucida Grande','Lucida Sans Unicode','Lucida Sans',Tahoma,sans-serif;font-size:46px;line-height:120%;text-align:left;mso-line-height-alt:55.199999999999996px;">
                                                                        <p style="margin: 0; word-break: break-word;"><strong><span>Olá, ${dados.nomeRepresentante}!</span></strong></p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                            <tr>
                                                                <td class="pad" style="padding-bottom:5px;padding-left:30px;padding-right:30px;padding-top:15px;">
                                                                    <div style="color:#555555;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;font-size:20px;line-height:150%;text-align:left;mso-line-height-alt:30px;">
                                                                        <p style="margin: 0; word-break: break-word;"><strong><span>${dados.nomeRepresentante}, segue sua cotação realizada no site da Entrega Já. Agora você aguardará nossos analistas entrarem em contato para mais informações.</span></strong></p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table class="paragraph_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                            <tr>
                                                                <td class="pad" style="padding-bottom:20px;padding-left:30px;padding-right:30px;">
                                                                    <div style="color:#7C7C7C;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;font-size:12px;line-height:150%;text-align:left;mso-line-height-alt:18px;">
                                                                        <p style="margin: 0; word-break: break-word;">&nbsp;</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #F4F4F4;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF; color: #000000; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-left: 5px; padding-right: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <table class="paragraph_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                            <tr>
                                                                <td class="pad" style="padding-left:35px;padding-right:10px;padding-top:25px;">
                                                                    <div style="color:#444444;font-family:'Montserrat','Trebuchet MS','Lucida Grande','Lucida Sans Unicode','Lucida Sans',Tahoma,sans-serif;font-size:24px;line-height:120%;text-align:left;mso-line-height-alt:28.799999999999997px;">
                                                                        <p style="margin: 0; word-break: break-word;"><span style="background-color: #93f5ed;"><span style="background-color: #93f5ed;"><span style="background-color: #93f5ed;">&nbsp;E agora?</span></span></span></p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table><!--[if mso]><style>#list-r4c0m1 ul{ margin: 0 !important; padding: 0 !important; } #list-r4c0m1 ul li{ mso-special-format: bullet; }#list-r4c0m1 .levelOne li { margin-top: 0 !important; } #list-r4c0m1 .levelOne { margin-left: -20px !important; }#list-r4c0m1 .levelTwo li { margin-top: 0 !important; } #list-r4c0m1 .levelTwo { margin-left: 20px !important; }#list-r4c0m1 .levelThree li { margin-top: 0 !important; } #list-r4c0m1 .levelThree { margin-left: 60px !important; }#list-r4c0m1 .levelFour li { margin-top: 0 !important; } #list-r4c0m1 .levelFour { margin-left: 100px !important; }#list-r4c0m1 .levelFive li { margin-top: 0 !important; } #list-r4c0m1 .levelFive { margin-left: 140px !important; }#list-r4c0m1 .levelSix li { margin-top: 0 !important; } #list-r4c0m1 .levelSix { margin-left: 180px !important; }#list-r4c0m1 .levelSeven li { margin-top: 0 !important; } #list-r4c0m1 .levelSeven { margin-left: 220px !important; }#list-r4c0m1 .levelEight li { margin-top: 0 !important; } #list-r4c0m1 .levelEight { margin-left: 260px !important; }#list-r4c0m1 .levelNine li { margin-top: 0 !important; } #list-r4c0m1 .levelNine { margin-left: 300px !important; }</style><![endif]-->
                                                        <table class="list_block block-2" id="list-r4c0m1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                            <tr>
                                                                <td class="pad" style="padding-bottom:30px;padding-left:50px;padding-right:20px;padding-top:15px;">
                                                                    <div class="levelOne" style="margin-left: 0;">
                                                                        <ul class="leftList" start="1" style="margin-top: 0; margin-bottom: 0; padding: 0; padding-left: 20px; color: #555555; font-family: Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif; font-size: 16px; line-height: 180%; text-align: left; mso-line-height-alt: 28.8px; list-style-type: disc;">
                                                                            <li style="margin-bottom: 0; text-align: left;"><span>Agora nossos analistas estão verificando sua cotação.</span></li>
                                                                            <li style="margin-bottom: 0; text-align: left;"><span style="font-size:16px;line-height:28px;">Os analistas entrarão em contato com você.</span></li>
                                                                            <li style="margin-bottom: 0; text-align: left;"><span>Passo de negociação junto ao analista.</span></li>
                                                                            <li style="margin-bottom: 0; text-align: left;"><span>Tudo certo? Fecharemos seu frete!</span></li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #F4F4F4;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 25px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <table class="button_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div class="alignment" align="center"><!--[if mso]>
    <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:72px;width:335px;v-text-anchor:middle;" arcsize="13%" stroke="false" fillcolor="#3D3BEE">
    <w:anchorlock/>
    <v:textbox inset="0px,0px,0px,0px">
    <center style="color:#ffffff; font-family:Arial, sans-serif; font-size:26px">
    <![endif]-->
                                                                        <div style="text-decoration:none;display:inline-block;color:#ffffff;background-color:#3D3BEE;border-radius:9px;width:auto;border-top:0px solid transparent;font-weight:undefined;border-right:0px solid transparent;border-bottom:0px solid transparent;border-left:0px solid transparent;padding-top:10px;padding-bottom:10px;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;font-size:26px;text-align:center;mso-border-alt:none;word-break:keep-all;"><span style="padding-left:45px;padding-right:45px;font-size:26px;display:inline-block;letter-spacing:normal;"><span style="word-break:break-word;"><span style="line-height: 52px;" data-mce-style><strong>Ver minha Cotação</strong></span></span></span></div><!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="row row-7" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #F4F4F4; background-image: url('https://d1oco4z2z1fhwp.cloudfront.net/templates/default/286/bg_wave_2.png'); background-position: top center; background-repeat: repeat;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <div class="spacer_block block-1" style="height:90px;line-height:90px;font-size:1px;">&#8202;</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="row row-8" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF; color: #000000; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 35px; padding-top: 15px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <table class="paragraph_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                            <tr>
                                                                <td class="pad" style="padding-left:30px;padding-right:30px;padding-top:15px;">
                                                                    <div style="color:#7C7C7C;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;font-size:14px;line-height:180%;text-align:center;mso-line-height-alt:25.2px;">
                                                                        <p style="margin: 0;"><strong>Entrega Já</strong>, clicou, entregou!</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table class="social_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div class="alignment" align="center">
                                                                        <table class="social-table" width="111px" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block;">
                                                                            <tr>
                                                                                <td style="padding:0 5px 0 0px;"><a href="https://www.facebook.com/entregajabr" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/t-only-logo-default-gray/facebook@2x.png" width="32" height="32" alt="Facebook" title="Facebook" style="display: block; height: auto; border: 0;"></a></td>
                                                                                <td style="padding:0 5px 0 0px;"><a href="https://www.instagram.com/entregajabr/" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/t-only-logo-default-gray/instagram@2x.png" width="32" height="32" alt="Instagram" title="Instagram" style="display: block; height: auto; border: 0;"></a></td>
                                                                                <td style="padding:0 5px 0 0px;"><a href="https://www.linkedin.com/company/entregajabr/mycompany/" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/t-only-logo-default-gray/linkedin@2x.png" width="32" height="32" alt="LinkedIn" title="LinkedIn" style="display: block; height: auto; border: 0;"></a></td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="row row-9" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 700px; margin: 0 auto;" width="700">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                        <table class="icons_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                            <tr>
                                                                <td class="pad" style="vertical-align: middle; color: #1e0e4b; font-family: 'Inter', sans-serif; font-size: 15px; padding-bottom: 5px; padding-top: 5px; text-align: center;">
                                                                    <table width="100%" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                        <tr>
                                                                            <td class="alignment" style="vertical-align: middle; text-align: center;"><!--[if vml]><table align="center" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;"><![endif]-->
                                                                                <!--[if !vml]><!-->
                                                                                <table class="icons-inner" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;" cellpadding="0" cellspacing="0" role="presentation"><!--<![endif]-->
                                                                                    <tr>
                                                                                        <td style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 6px;"><a href="http://designedwithbeefree.com/" target="_blank" style="text-decoration: none;"><img class="icon" alt="Beefree Logo" src="https://d1oco4z2z1fhwp.cloudfront.net/assets/Beefree-logo.png" height="32" width="34" align="center" style="display: block; height: auto; margin: 0 auto; border: 0;"></a></td>
                                                                                        <td style="font-family: 'Inter', sans-serif; font-size: 15px; font-weight: undefined; color: #1e0e4b; vertical-align: middle; letter-spacing: undefined; text-align: center;"><a href="http://designedwithbeefree.com/" target="_blank" style="color: #1e0e4b; text-decoration: none;">Designed with Beefree</a></td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table><!-- End -->
    </body>
    
    </html>`)

    useEffect(() => {
        visualizacaoResumo()
        const dadosJson = JSON.parse(localStorage.getItem('dados'))
        if (dadosJson) {
            console.log(dadosJson)
            setDados(dadosJson)
            let cub = 0
            let pesCub = 0
            dadosJson.volumes.forEach((d) => {
                cub = cub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom))
                pesCub = pesCub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom) * 300) * d.qVol
            })
            setCubagem(parseFloat(cub))
            setPesoCubado(parseFloat(pesCub))
        }
    }, [])

    function visualizacaoResumo() {
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                visualizacaoResumo: parseInt(res.data().visualizacaoResumo) + parseInt(1),
            })
        })
    }

    function enviaEmail() {
        firebase
            .firestore()
            .collection("mail")
            .add({
                to: "guilherme.si2012@gmail.com",
                message: {
                    subject: "Bem-vindo(a) ao Projeto Escola Fábrica de Sonhos Virtuais!",
                    html: textoEmail,
                },
            })
            .then(() => console.log("Queued email for delivery!"));
    }

    function geraCotacao() {
        setLoading(true)
        let pesoBruto = 0
        let qtdVol = 0
        let pesoCubado = 0

        dados.volumes.forEach((d) => {
            pesoBruto = parseFloat(pesoBruto) + parseFloat(d.pBru)
            qtdVol = parseInt(qtdVol) + parseInt(d.qVol)
            pesoCubado = parseFloat(pesoCubado) + ((parseFloat(d.dAlt / 100) * parseFloat(d.dLar / 100) * parseFloat(d.dCom / 100)) * 300) * parseInt(d.qVol)
        })
        const data = {
            nDocEmit: "27069620000133",
            nDocCli: "27069620000133",
            cOrigCalc: dados.origem.ibge,
            cDestCalc: dados.destino.ibge,
            cServ: dados.servico,
            pBru: parseFloat(pesoBruto),
            cTab: dados.tabela,
            qVol: parseInt(qtdVol),
            vNF: parseFloat(dados.valor),
            volumes: dados.volumes
        }
        setCotacao({})
        mensagemLoading('Buscando Cotação..')
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                confirmacaoResultado: parseInt(res.data().confirmacaoResultado) + parseInt(1),
            }).then(() => {
                login().then((res) => {
                    postFrete(res.data.access_key, data).then((doc) => {
                        if (doc.message === 'OK') {
                            setDadosEnvioCotacao(data)
                            let val = 0
                            doc.data.forEach((d) => {
                                val = parseFloat(val) + parseFloat(d.vTotal)
                            })
                            if (val > 0) {
                                Swal.close();
                                const data = {
                                    dados: doc.data.filter((d) => d.vPeso > 0),
                                    frete: {
                                        produtos: dados.volumes,
                                        cepOrigem: dados.origem,
                                        cepDestino: dados.destino,
                                        servico: dados.servico
                                    }
                                }
                                if (dados.idCotacaoAgendor) {
                                    atualizaCotacao(data)
                                } else {
                                    enviaCotacao(data)
                                }
                                setCotacao(data)
                                setTrecho(true)
                            } else {
                                mensagemAlerta('Trecho da cotação ainda não disponível')
                                salvaCotacao(dados.volumes)
                            }
                        } else {
                            mensagemAlerta(doc.message)
                        }
                    })
                })
            })
        })
    }

    function salvaCotacao(volumes) {
        servicos.cotacao.get().then((q) => {
            servicos.cotacao.add({
                empresa: {
                    nome: dados.nomeEmpresa,
                    whatsapp: dados.whatsapp,
                    email: dados.email,
                    cnpj: dados.cnpjEmpresa,
                    representante: dados.nomeRepresentante,
                },
                origem: dados.origem,
                destino: dados.destino,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                mes: moment().format('MM'),
                ano: moment().format('YYYY'),
                servico: dados.servico,
                advalore: parseFloat(0),
                icms: parseFloat(0),
                gris: parseFloat(0),
                pedagio: parseFloat(0),
                entrega: dados.entrega,
                prazo: 0,
                valorNota: dados.valor,
                pagamentoFrete: dados.pagamentoFrete,
                valorTotal: parseFloat(0),
                trecho: false,
                valorFrete: parseFloat(0),
                cotacao: 'COT' + (q.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY')
            }).then((snap) => {
                servicos.cotacao.doc(snap.id).update({
                    id: snap.id
                }).then(() => {
                    setIdCotacao(snap.id)
                    dados.idFirebase = snap.id
                    localStorage.setItem('dados', JSON.stringify(dados))
                    volumes.forEach((d) => {
                        servicos.cotacao.doc(snap.id).collection('produtos').add(d)
                    })
                    Swal.close()
                    setLoading(false)
                })
            }).catch((er) => {
                mensagemErro('Erro ao cadastrar cotação ' + er)
            })
        })
    }

    function enviaCotacao(data) {
        return (
            servicos.cotacao.get().then((q) => {
                servicos.cotacao.add({
                    empresa: {
                        nome: dados.nomeEmpresa,
                        whatsapp: dados.whatsapp,
                        email: dados.email,
                        cnpj: dados.cnpjEmpresa,
                        representante: dados.nomeRepresentante,
                    },
                    origem: dados.origem,
                    destino: dados.destino,
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    mes: moment().format('MM'),
                    ano: moment().format('YYYY'),
                    servico: dados.servico,
                    advalore: parseFloat(data.dados[0].vAdv),
                    icms: parseFloat(data.dados[0].vICMS),
                    gris: parseFloat(data.dados[0].vGris),
                    pedagio: parseFloat(data.dados[0].vPedagio),
                    entrega: dados.entrega,
                    prazo: data.dados.filter((d) => d.vPeso > 0)[0].nDias,
                    valorNota: dados.valor,
                    pagamentoFrete: dados.pagamentoFrete,
                    valorTotal: parseFloat(data.dados[0].vTotal),
                    trecho: true,
                    status: 'PENDENTE',
                    tipo: 'PUBLICO',
                    valorFrete: (parseFloat(data.dados[0].vTotal) - (parseFloat(data.dados[0].vAdv) + parseFloat(data.dados[0].vGris) + parseFloat(data.dados[0].vICMS) + parseFloat(data.dados[0].vPedagio))),
                    cotacao: 'COT' + (q.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY')
                }).then((snap) => {
                    servicos.cotacao.doc(snap.id).update({
                        id: snap.id
                    }).then(() => {
                        setIdCotacao(snap.id)
                        dados.idFirebase = snap.id
                        localStorage.setItem('dados', JSON.stringify(dados))
                        data.frete.produtos.forEach((d, index) => {
                            var idProd = 'prod' + index
                            const dt = {
                                ...d,
                                id: idProd
                            }
                            servicos.cotacao.doc(snap.id).collection('produtos').doc(idProd).set(dt)
                        })
                        // // Enviar para o agendor
                        const dadosEmpresa = {
                            name: dados.nomeEmpresa,
                            cnpj: dados.cnpjEmpresa,
                            ranking: "3",
                            contact: {
                                email: dados.email,
                                whatsapp: dados.whatsapp
                            }
                        }
                        Swal.close()
                        setLoading(false)
                        if (dados.idEmpresaAgendor) {
                            const dadosNegocio = {
                                title: 'Cotação ' + dados.nomeEmpresa + ' - ' + 'COT' + (q.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY'),
                                dealStatusText: "ongoing",
                                description: "Cotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + snap.id,
                                dealStage: 2918853,
                                value: parseFloat(data.dados[0].vTotal),
                                funnel: 714912,
                                ranking: 5,
                                ownerUser: 'renata.soares@entregaja.com.br',
                                customFields: {
                                    link: "https://cotacao.entregaja.com.br/resultadoCotacao?c=" + snap.id
                                }

                            }
                            geraCotacaoNegocio(dados.idEmpresaAgendor, dadosNegocio).then((resNegocio) => {
                                dados.idCotacaoAgendor = resNegocio.data.id
                                localStorage.setItem('dados', JSON.stringify(dados))
                                servicos.cotacao.doc(snap.id).update({
                                    idCotacaoAgendor: resNegocio.data.id,
                                    idEmpresaAgendor: dados.idEmpresaAgendor
                                })
                                const dadosTask = {
                                    text: 'Cotação ' + dados.nomeEmpresa + "\n\nCotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + snap.id,
                                    type: "PROPOSTA",
                                }
                                geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                                    Swal.close()
                                    setLoading(false)
                                })
                            })
                        } else {
                            criaEmpresa(dadosEmpresa).then((resEmpresa) => {
                                const dadosNegocio = {
                                    title: 'Cotação ' + dados.nomeEmpresa + ' - ' + 'COT' + (q.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY'),
                                    dealStatusText: "ongoing",
                                    description: "Cotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + snap.id,
                                    dealStage: 2918853,
                                    value: parseFloat(data.dados[0].vTotal),
                                    funnel: 714912,
                                    ranking: 5,
                                    ownerUser: 'renata.soares@entregaja.com.br',
                                    customFields: {
                                        link: "https://cotacao.entregaja.com.br/resultadoCotacao?c=" + snap.id
                                    }

                                }
                                geraCotacaoNegocio(resEmpresa.data.id, dadosNegocio).then((resNegocio) => {
                                    dados.idCotacaoAgendor = resNegocio.data.id
                                    dados.idEmpresaAgendor = resEmpresa.data.id
                                    localStorage.setItem('dados', JSON.stringify(dados))

                                    servicos.cotacao.doc(snap.id).update({
                                        idCotacaoAgendor: resNegocio.data.id,
                                        idEmpresaAgendor: resEmpresa.data.id
                                    })
                                    const dadosTask = {
                                        text: 'Cotação ' + dados.nomeEmpresa + "\n\nCotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + snap.id,
                                        type: "PROPOSTA",
                                    }
                                    geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                                        Swal.close()
                                        setLoading(false)
                                    })
                                })
                            })
                        }

                    })
                }).catch((er) => {
                    mensagemErro('Erro ao cadastrar cotação ' + er)
                })
            })
        )
    }

    function atualizaCotacao(data) {
        return (

            servicos.cotacao.doc(dados.idFirebase).update({
                empresa: {
                    nome: dados.nomeEmpresa,
                    whatsapp: dados.whatsapp,
                    email: dados.email,
                    cnpj: dados.cnpjEmpresa,
                    representante: dados.nomeRepresentante,
                },
                origem: dados.origem,
                destino: dados.destino,
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                mes: moment().format('MM'),
                ano: moment().format('YYYY'),
                servico: dados.servico,
                advalore: parseFloat(data.dados[0].vAdv),
                icms: parseFloat(data.dados[0].vICMS),
                gris: parseFloat(data.dados[0].vGris),
                pedagio: parseFloat(data.dados[0].vPedagio),
                entrega: dados.entrega,
                prazo: data.dados.filter((d) => d.vPeso > 0)[0].nDias,
                valorNota: dados.valor,
                pagamentoFrete: dados.pagamentoFrete,
                valorTotal: parseFloat(data.dados[0].vTotal),
                trecho: true,
                valorFrete: (parseFloat(data.dados[0].vTotal) - (parseFloat(data.dados[0].vAdv) + parseFloat(data.dados[0].vGris) + parseFloat(data.dados[0].vICMS) + parseFloat(data.dados[0].vPedagio))),
            }).then((snap) => {
                setIdCotacao(dados.idFirebase)
                localStorage.setItem('dados', JSON.stringify(dados))
                servicos.cotacao.doc(dados.idFirebase).collection('produtos').get().then((dadosProduto) => {
                    const dadosProd = dadosProduto.docs.map((d) => d.data());
                    dadosProd.forEach((d) => {
                        servicos.cotacao.doc(dados.idFirebase).collection('produtos').doc(d.id).delete();
                    })
                }).then(() => {
                    // // Enviar para o agendor
                    const dadosNegocio = {
                        value: parseFloat(data.dados[0].vTotal),
                    }

                    atualizaCotacaoNegocio(dados.idCotacaoAgendor, dadosNegocio).then((resNegocio) => {
                        Swal.close()
                        setLoading(false)
                        data.frete.produtos.forEach((d, index) => {
                            var idProd = 'prod' + index
                            const dt = {
                                ...d,
                                id: idProd
                            }
                            servicos.cotacao.doc(dados.idFirebase).collection('produtos').doc(idProd).set(dt)
                        })
                    })
                })

            }).catch((er) => {
                mensagemErro('Erro ao cadastrar cotação ' + er)
            })
        )
    }

    function novaCotacao() {
        localStorage.setItem('dados', '')
        window.location = '/cotacao'
    }

    // function geraColeta() {
    //     const dadosEnvioCotacao = {};

    //     dadosEnvioCotacao.solicit = dados.nomeEmpresa
    //     dadosEnvioCotacao.telSolicit = dados.whatsapp.replace(/[^a-z0-9]/gi, '')
    //     dadosEnvioCotacao.rem = {
    //         nDoc: '00233065002392',
    //         IE: '489385273',
    //         xNome: 'UNIDOCKYS ASSESSORIA E LOGISTICA DE MATERIAIS LTDA',
    //         xFant: 'UNIDOCKYS ASSESSORIA E LOGISTICA DE MATERIAIS LTDA',
    //         xLgr: dadosCepOrigem.logradouro,
    //         nro: numeroOrigem,
    //         xBairro: dadosCepOrigem.bairro,
    //         cMun: 1,
    //         CEP: dadosCepOrigem.cep,
    //         cPais: 1058,
    //     }
    //     dadosEnvioCotacao.dest = {
    //         nDoc: documentoDestino.replace(/[^a-z0-9]/gi, ''),
    //         IE: ieDestino,
    //         xNome: nomeDestino,
    //         xFant: nomeDestino,
    //         xLgr: dadosCepDestino.logradouro,
    //         nro: numeroDestino,
    //         xBairro: dadosCepDestino.bairro,
    //         cMun: 3251,
    //         CEP: dadosCepDestino.cep,
    //         cPais: 1058,
    //     }
    //     console.log(dadosEnvioCotacao)
    //     login().then((res) => {
    //         postCotacao(res.data.access_key, dadosEnvioCotacao).then((doc) => {
    //             if (doc.message === 'OK') {
    //                 console.log(doc)
    //             } else {
    //                 console.log('erro ' + doc)
    //             }
    //         })
    //     })
    // }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
                <div class="row">
                    <div class="col-xl-4">
                        <div style={{ padding: 10 }} class="card mb-4">
                            <h1 style={{ marginLeft: 20 }} class="mb-0">Resultado</h1>
                            {/* <img style={{ width: '100%', height: '100%' }} src='/images/resultado.png' /> */}
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="card mb-4">
                            <div class="card-header align-items-center">
                                <div style={{ textAlign: 'center' }}>
                                    <img src='images/logo.png' />
                                </div>
                                <h4 style={{ marginTop: 20 }} class="mb-0">Resumo das informações</h4>
                                <p>Antes de gerar a cotação, verifique seus dados.</p>
                            </div>
                            {dados.nomeEmpresa ?
                                <div class="card-body">
                                    <div className='row'>
                                        <div class="mb-3 row">
                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>1. Dados do solicitante</b></h5>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>CNPJ</label>
                                                <p>{dados.cnpjEmpresa}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>Empresa</label>
                                                <p>{dados.nomeEmpresa}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>Representante</label>
                                                <p>{dados.nomeRepresentante}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>Whatsapp</label>
                                                <p>{dados.whatsapp}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>Email</label>
                                                <p>{dados.email}</p>
                                            </div>
                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>2. Origem e Destino</b></h5>
                                            <label style={{ marginTop: 10 }}>Origem</label>
                                            <p>
                                                {dados.origem.logradouro}, {dados.origem.bairro}<br />
                                                {dados.origem.localidade} - {dados.origem.uf} - CEP {dados.origem.cep}

                                            </p>
                                            <label style={{ marginTop: 10 }}>Destino</label>
                                            <p>
                                                {dados.destino.logradouro}, {dados.destino.bairro}<br />
                                                {dados.destino.localidade} - {dados.destino.uf} - CEP {dados.destino.cep}

                                            </p>

                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>3. Volumes - {dados.volumes.length} volume(s)</b></h5>
                                            <table class="table" style={{ marginTop: 20 }}>
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th>medidas(Compr x Larg x Alt)</th>
                                                        <th>Peso</th>
                                                        <th>Qtd</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    {dados.volumes.map((p, index) =>
                                                        <tr>
                                                            <td>{p.nome}</td>
                                                            <td>{parseInt(p.dCom * 100)} cm {parseInt(p.dLar * 100)} cm x {parseInt(p.dAlt * 100)} cm </td>
                                                            <td>{p.pBru} kg</td>
                                                            <td>{p.qVol}</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                            <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                <label >Cubagem</label>
                                                <p>{cubagem.toFixed(3)} m³</p>
                                            </div>
                                            <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                <label >Peso Cubado</label>
                                                <p>{pesoCubado.toFixed(2)} kg</p>
                                            </div>
                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>4. Serviço e valor</b></h5>
                                            {dados.servico == '080' ?
                                                <div className='col-md-3'>
                                                    <label style={{ marginTop: 20 }}>Serviço</label>
                                                    <p><b>Entrega Convencional</b></p>
                                                </div>
                                                : dados.servico == '081' ?
                                                    <>
                                                        <div className='col-md-4'>
                                                            <label style={{ marginTop: 20 }}>Serviço</label>
                                                            <p><b>Entrega Convencional</b></p>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                            <div className='col-md-3'>
                                                <label style={{ marginTop: 20 }}>Valor da Nota</label>
                                                <p><b>{(parseFloat(dados.valor)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></p>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ marginTop: 20 }}>Pagamento Frete</label>
                                                <p><b>{dados.pagamentoFrete === 'ORIGEM' ? 'Pagamento na Origem' : 'Pagamento na Entrega'}</b></p>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ marginTop: 20 }}>Entrega</label>
                                                <p><b>{dados.entrega === 'BASE' ? 'Retirada na Base' : 'Entrega a Domicílio'}</b></p>
                                            </div>

                                        </div>
                                        {cotacao.dados ?
                                            <>
                                                <div class="mb-3 col-md-12">
                                                    <hr />
                                                    <h5 style={{ marginTop: 20 }} class="mb-0"><b>Resultado da Cotação</b></h5>
                                                </div>
                                                <div style={{ marginTop: 20 }} className='col-md-9'>
                                                    Prazo Previsto de Entrega
                                                </div>
                                                <div className='col-md-3'>
                                                    De {cotacao.dados ? cotacao.dados[0].nDias - 2 : 0} á {cotacao.dados ? cotacao.dados[0].nDias : 0} dias úteis
                                                </div>
                                                <div style={{ marginTop: 30 }} className='col-md-8'>
                                                    <b style={{ fontSize: 20 }}>Valor Total do Frete</b>
                                                </div>
                                                <div style={{ marginTop: 20 }} className='col-md-4'>
                                                    <b style={{ fontSize: 30 }}>{(parseFloat(cotacao.dados[0].vTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b>
                                                </div>
                                                <div style={{ marginTop: 20 }} className='col-md-12'>
                                                    <label>Observação</label>
                                                    <p>Informamos que o valor indicado nesta cotação é uma estimativa, baseada nas informações preliminares fornecidas, como cubagem, peso e local de entrega. Destacamos que a taxa de coleta, assim como outros detalhes da cotação, deverá ser confirmada diretamente com um de nossos consultores no momento da geração da cotação. Estamos à disposição para esclarecer dúvidas e oferecer suporte em todas as etapas do processo.</p>
                                                </div>
                                            </>
                                            :
                                            null
                                        }

                                        <div class="mb-3 col-md-12">

                                        </div>
                                        {!loading && !idCotacao ?
                                            <>
                                                <div class="mb-3 col-md-9">
                                                    <a href='/servico' type="submit" class="btn btn-danger">Voltar</a>
                                                </div>
                                                <div class="mb-3 col-md-3">
                                                    <button onClick={() => geraCotacao()} type="submit" class="btn btn-primary">Gerar Cotação</button>
                                                </div>
                                            </>
                                            : trecho ?
                                                <>
                                                    <div class="mb-3 col-md-3">
                                                        <button onClick={() => novaCotacao()} type="submit" class="btn btn-info">Nova Cotação</button>
                                                    </div>
                                                    <div class="mb-3 col-md-5">
                                                        <a href='/servico' type="submit" class="btn btn-danger">Editar</a>
                                                    </div>
                                                    <div class="mb-3 col-md-4">
                                                        <a href={`https://wa.me/551134750544?text=Olá vim através do site de cotação da Entrega Já. Gostaria de analisar esta cotação: *https://cotacao.entregaja.com.br/resultadoCotacao?c=${idCotacao}*`} type="button" class="btn btn-success">
                                                            <i class='bx bxl-whatsapp'></i>Enviar ao consultor</a>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div class="mb-3 col-md-3">
                                                        <button onClick={() => novaCotacao()} type="submit" class="btn btn-info">Nova Cotação</button>
                                                    </div>
                                                    <div class="mb-3 col-md-5">
                                                        <a href='/servico' type="submit" class="btn btn-danger">Editar</a>
                                                    </div>
                                                    <div class="mb-3 col-md-4">
                                                        <a href={`https://wa.me/551134750544?text=Olá vim através do site de cotação da Entrega Já. Gostaria de saber qual previsão para este trecho: *https://cotacao.entregaja.com.br/resultadoCotacao?c=${idCotacao}*`} type="button" class="btn btn-success">
                                                            <i class='bx bxl-whatsapp'></i>Falar com um consultor</a>
                                                    </div>
                                                </>
                                        }


                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div class="content-backdrop fade"></div>
        </div>

    );
}