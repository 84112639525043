import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Site
import Login from './pages/Login'
import Acesso from './pages/Acesso'

import Home from './pages/Home'
import Rastreio from './pages/Rastreio'
import Cadastro from './pages/Cadastro'

// Admin
import Admin from './pages/Admin'
import ListaCotacao from './pages/Admin/Cotacao/Lista'
import DetalheCotacao from './pages/Admin/Cotacao'
import ListaAgentes from './pages/Admin/Agentes'
import CotacoesAgente from './pages/Admin/Agentes/Cotacoes'

// Cotação Externa
import CotacaoExterna from './pages/CotacaoExterna'
import Solicitante from './pages/CotacaoExterna/Solicitante'
import OrigemDestino from './pages/CotacaoExterna/OrigemDestino'
import Volume from './pages/CotacaoExterna/Volume'
import Servico from './pages/CotacaoExterna/Servico'
import Resultado from './pages/CotacaoExterna/Resultado'
import ResultadoCotacao from './pages/CotacaoExterna/ResultadoCotacao'

// Agente
import Agente from './pages/Agente'
import DetalheAgente from './pages/Agente/Detalhe'
import CotacaoAgente from './pages/Agente/Cotacao'
import ListaCotacaoAgente from './pages/Agente/Cotacao/Lista'
import DetalheCotacaoAgente from './pages/Agente/Cotacao/Detalhe'
import ListaEmpresasAgente from './pages/Agente/Empresas'


export default function Rotas() {
    return (
        <div className="App">
            <BrowserRouter>
                {/* Site */}
                <Route path="/" exact={true} component={Solicitante} />
                <Route path="/acesso" exact={true} component={Acesso} />

                {/* Admin */}
                <Route path="/admin" exact={true} component={Admin} />
                <Route path="/listaCotacao" exact={true} component={ListaCotacao} />
                <Route path="/detalheCotacao" exact={true} component={DetalheCotacao} />
                <Route path="/listaAgentes" exact={true} component={ListaAgentes} />
                <Route path="/cotacoesAgente" exact={true} component={CotacoesAgente} />

                <Route path="/home" exact={true} component={Home} />
                <Route path="/rastreio" exact={true} component={Rastreio} />
                <Route path="/cadastro" exact={true} component={Cadastro} />

                {/* Cotação Externa */}
                <Route path="/cotacao" exact={true} component={Solicitante} />
                {/* <Route path="/solicitante" exact={true} component={Solicitante} /> */}
                <Route path="/origemDestino" exact={true} component={OrigemDestino} />
                <Route path="/volumes" exact={true} component={Volume} />
                <Route path="/servico" exact={true} component={Servico} />
                <Route path="/resultado" exact={true} component={Resultado} />
                <Route path="/resultadoCotacao" exact={true} component={ResultadoCotacao} />

                {/* Agente */}
                <Route path="/agente" exact={true} component={Agente} />
                <Route path="/detalheAgente" exact={true} component={DetalheAgente} />
                <Route path="/cotacaoAgente" exact={true} component={CotacaoAgente} />
                <Route path="/listaCotacaoAgente" exact={true} component={ListaCotacaoAgente} />
                <Route path="/detalheCotacaoAgente" exact={true} component={DetalheCotacaoAgente} />
                <Route path="/listaEmpresasAgente" exact={true} component={ListaEmpresasAgente} />

            </BrowserRouter>
        </div>
    );
}