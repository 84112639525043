import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { login, postFrete } from '../../../services/Brudam'
import { criaEmpresa, geraCotacaoNegocio, geraTask } from '../../../services/Agendor'
import firebase from 'firebase';
import moment from 'moment';

export default function ResultadoCotacao() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('c'))

    const [servicos] = useState({
        cotacao: firebase.firestore().collection('cotacao'),
        mail: firebase.firestore().collection('mail'),
    })
    // Cotação
    const [idCotacao, setIdCotacao] = useState('')
    const [dados, setDados] = useState({})
    const [cubagem, setCubagem] = useState(0)
    const [pesoCubado, setPesoCubado] = useState(0)
    const [produtos, setProdutos] = useState([])

    useEffect(() => {
        servicos.cotacao.doc(id).get().then((res) => {
            setDados(res.data())
            console.log(res.data())
            servicos.cotacao.doc(id).collection('produtos').get().then((prod) => {
                const data = prod.docs.map((d) => d.data())
                setProdutos(data)
                let cub = 0
                let pesCub = 0
                data.forEach((d) => {
                    cub = cub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom))
                    pesCub = pesCub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom) * 300) * d.qVol
                })
                setCubagem(parseFloat(cub))
                setPesoCubado(parseFloat(pesCub))
            })
        })
    }, [])

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
                <div class="row">
                    <div class="col-xl-4">
                        <div style={{padding:10}} class="card mb-4">
                            <h1 style={{ marginLeft: 20 }} class="mb-0">Resultado</h1>
                            {/* <img style={{ width: '100%', height: '100%' }} src='/images/resultado.png' /> */}
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="card mb-4">
                            <div class="card-header align-items-center">
                                <div style={{ textAlign: 'center' }}>
                                    <img src='images/logo.png' />
                                </div>
                                <h4 style={{ marginTop: 20 }} class="mb-0">Resumo das informações</h4>
                                <p>Antes de gerar a cotação, verifique seus dados.</p>
                                <h5 style={{ marginTop: 20 }} class="mb-0">Cotação de N° <b>{dados.cotacao}</b></h5>
                            </div>
                            {dados.id ?
                                <div class="card-body">
                                    <div className='row'>
                                        <div class="mb-3 row">
                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>1. Dados do solicitante</b></h5>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>CNPJ</label>
                                                <p>{dados.empresa.cnpj}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>Empresa</label>
                                                <p>{dados.empresa.nome}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>Representante</label>
                                                <p>{dados.empresa.representante}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>Whatsapp</label>
                                                <p>{dados.empresa.whatsapp}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginTop: 10 }}>Email</label>
                                                <p>{dados.empresa.email}</p>
                                            </div>
                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>2. Origem e Destino</b></h5>
                                            <label style={{ marginTop: 10 }}>Origem</label>
                                            <p>
                                                {dados.origem.logradouro}, {dados.origem.bairro}<br />
                                                {dados.origem.localidade} - {dados.origem.uf} - CEP {dados.origem.cep}

                                            </p>
                                            <label style={{ marginTop: 10 }}>Destino</label>
                                            <p>
                                                {dados.destino.logradouro}, {dados.destino.bairro}<br />
                                                {dados.destino.localidade} - {dados.destino.uf} - CEP {dados.destino.cep}

                                            </p>

                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>3. Volumes - {produtos.length} volume(s)</b></h5>
                                            <table class="table" style={{ marginTop: 20 }}>
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th>medidas(Compr x Larg x Alt)</th>
                                                        <th>Peso</th>
                                                        <th>Qtd</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    {produtos.map((p, index) =>
                                                        <tr>
                                                            <td>{p.nome}</td>
                                                            <td>{parseInt(p.dCom * 100)} cm {parseInt(p.dLar * 100)} cm x {parseInt(p.dAlt * 100)} cm </td>
                                                            <td>{p.pBru} kg</td>
                                                            <td>{p.qVol}</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                            <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                <label >Cubagem</label>
                                                <p>{cubagem.toFixed(3)} m³</p>
                                            </div>
                                            <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                <label >Peso Cubado</label>
                                                <p>{pesoCubado.toFixed(2)} kg</p>
                                            </div>
                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>4. Serviço e valor</b></h5>
                                            {dados.servico == '080' ?
                                                <div className='col-md-3'>
                                                    <label style={{ marginTop: 20 }}>Serviço</label>
                                                    <p><b>Entrega Convencional</b></p>
                                                </div>
                                                : dados.servico == '081' ?
                                                    <>
                                                        <div className='col-md-4'>
                                                            <label style={{ marginTop: 20 }}>Serviço</label>
                                                            <p><b>Entrega Convencional</b></p>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                            <div className='col-md-3'>
                                                <label style={{ marginTop: 20 }}>Valor da Nota</label>
                                                <p><b>{(parseFloat(dados.valorNota)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></p>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ marginTop: 20 }}>Pagamento Frete</label>
                                                <p><b>{dados.pagamentoFrete === 'ORIGEM' ? 'Pagamento na Origem' : 'Pagamento na Entrega'}</b></p>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ marginTop: 20 }}>Entrega</label>
                                                <p><b>{dados.entrega === 'BASE' ? 'Retirada na Base' : 'Entrega a Domicílio'}</b></p>
                                            </div>

                                        </div>
                                        <div class="mb-3 col-md-12">
                                            <hr />
                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>Resultado da Cotação</b></h5>
                                        </div>
                                        <div style={{ marginTop: 20 }} className='col-md-9'>
                                            Prazo Previsto de Entrega
                                        </div>
                                        <div className='col-md-3'>
                                            De {dados.prazo - 2} á {dados.prazo} dias úteis
                                        </div>
                                        <div style={{ marginTop: 30 }} className='col-md-8'>
                                            <b style={{ fontSize: 20 }}>Valor Total do Frete</b>
                                        </div>
                                        <div style={{ marginTop: 20 }} className='col-md-4'>
                                            <b style={{ fontSize: 30 }}>{(parseFloat(dados.valorTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b>
                                        </div>
                                        <div style={{ marginTop: 20 }} className='col-md-12'>
                                            <label>Observação</label>
                                            <p>Informamos que o valor indicado nesta cotação é uma estimativa, baseada nas informações preliminares fornecidas, como cubagem, peso e local de entrega. Destacamos que a taxa de coleta, assim como outros detalhes da cotação, deverá ser confirmada diretamente com um de nossos consultores no momento da geração da cotação. Estamos à disposição para esclarecer dúvidas e oferecer suporte em todas as etapas do processo.</p>
                                        </div>

                                        <div class="mb-3 col-md-12">

                                        </div>


                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div class="content-backdrop fade"></div>
        </div>

    );
}