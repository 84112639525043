import React, { useEffect, useState } from 'react';
import firebase from 'firebase';

export default function Menu() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [usuario, setUsuario] = useState({})

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
            }
        })
    }, {})
    return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo">
                <a href="/home" className="app-brand-link">
                    <span className="app-brand-logo demo">
                        <img style={{ width: '80%' }} src="https://entregaja.com.br/assets/images/logo/logo.png" />
                    </span>
                    <span className="app-brand-text demo menu-text fw-bolder ms-2">Sneat</span>
                </a>
                <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
                    <i className="bx bx-chevron-left bx-sm align-middle" />
                </a>
            </div>
            <div className="menu-inner-shadow" />
            {usuario.id && usuario.usuario === 'COTACAOADMIN' ?
                <ul className="menu-inner py-1">
                    <li className="menu-item active">
                        <a href="/admin" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-home-circle" />
                            <div data-i18n="Analytics">Dashboard</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="/listaCotacao" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-dock-top" />
                            <div data-i18n="Account Settings">Cotações</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="/listaAgentes" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-user" />
                            <div data-i18n="Authentications">Agentes</div>
                        </a>
                    </li>
                    {/* <li className="menu-item">
                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-lock-open-alt" />
                            <div data-i18n="Authentications">Usuários</div>
                        </a>
                    </li> */}
                    
                </ul>
                : usuario.id && usuario.usuario === 'AGENTE' ?
                    <ul className="menu-inner py-1">
                        <li className="menu-item active">
                            <a href="/agente" className="menu-link">
                                <i className="menu-icon tf-icons bx bx-home-circle" />
                                <div data-i18n="Analytics">Dashboard</div>
                            </a>
                        </li>
                        {usuario.status === 'ATIVO' ?
                            <>
                                <li className="menu-header small text-uppercase">
                                    <span className="menu-header-text">Serviços</span>
                                </li>
                                <li className="menu-item">
                                    <a href="/listaCotacaoAgente" className="menu-link menu-toggle">
                                        <i className="menu-icon tf-icons bx bx-file" />
                                        <div data-i18n="Account Settings">Minhas Cotações</div>
                                    </a>
                                </li>
                                {/* <li className="menu-item">
                                    <a href="javascript:void(0);" className="menu-link menu-toggle">
                                        <i className="menu-icon tf-icons bx bx-money" />
                                        <div data-i18n="Account Settings">Minhas Comissões</div>
                                    </a>
                                </li> */}
                                <li className="menu-item">
                                    <a href="/listaEmpresasAgente" className="menu-link menu-toggle">
                                        <i className="menu-icon tf-icons bx bx-briefcase" />
                                        <div data-i18n="Account Settings">Meus Clientes</div>
                                    </a>
                                </li>
                            </>
                            :
                            null
                        }
                        <li className="menu-item">
                            <a href="/detalheAgente" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons bx bx-user-pin" />
                                <div data-i18n="Account Settings">Meus Dados</div>
                            </a>
                        </li>


                    </ul>
                    :
                    null
            }
            {/* <li className="menu-item">
                <a href="javascript:void(0);" className="menu-link menu-toggle">
                    <i className="menu-icon tf-icons bx bx-layout" />
                    <div data-i18n="Layouts">Layouts</div>
                </a>
                <ul className="menu-sub">
                    <li className="menu-item">
                        <a href="layouts-without-menu.html" className="menu-link">
                            <div data-i18n="Without menu">Without menu</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="layouts-without-navbar.html" className="menu-link">
                            <div data-i18n="Without navbar">Without navbar</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="layouts-container.html" className="menu-link">
                            <div data-i18n="Container">Container</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="layouts-fluid.html" className="menu-link">
                            <div data-i18n="Fluid">Fluid</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="layouts-blank.html" className="menu-link">
                            <div data-i18n="Blank">Blank</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="menu-header small text-uppercase">
                <span className="menu-header-text">Pages</span>
            </li>
            <li className="menu-item">
                <a href="javascript:void(0);" className="menu-link menu-toggle">
                    <i className="menu-icon tf-icons bx bx-dock-top" />
                    <div data-i18n="Account Settings">Account Settings</div>
                </a>
                <ul className="menu-sub">
                    <li className="menu-item">
                        <a href="pages-account-settings-account.html" className="menu-link">
                            <div data-i18n="Account">Account</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="pages-account-settings-notifications.html" className="menu-link">
                            <div data-i18n="Notifications">Notifications</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="pages-account-settings-connections.html" className="menu-link">
                            <div data-i18n="Connections">Connections</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="menu-item">
                <a href="javascript:void(0);" className="menu-link menu-toggle">
                    <i className="menu-icon tf-icons bx bx-lock-open-alt" />
                    <div data-i18n="Authentications">Authentications</div>
                </a>
                <ul className="menu-sub">
                    <li className="menu-item">
                        <a href="auth-login-basic.html" className="menu-link" target="_blank">
                            <div data-i18n="Basic">Login</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="auth-register-basic.html" className="menu-link" target="_blank">
                            <div data-i18n="Basic">Register</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="auth-forgot-password-basic.html" className="menu-link" target="_blank">
                            <div data-i18n="Basic">Forgot Password</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="menu-item">
                <a href="javascript:void(0);" className="menu-link menu-toggle">
                    <i className="menu-icon tf-icons bx bx-cube-alt" />
                    <div data-i18n="Misc">Misc</div>
                </a>
                <ul className="menu-sub">
                    <li className="menu-item">
                        <a href="pages-misc-error.html" className="menu-link">
                            <div data-i18n="Error">Error</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="pages-misc-under-maintenance.html" className="menu-link">
                            <div data-i18n="Under Maintenance">Under Maintenance</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="menu-header small text-uppercase"><span className="menu-header-text">Components</span></li>
            
            <li className="menu-item">
                <a href="cards-basic.html" className="menu-link">
                    <i className="menu-icon tf-icons bx bx-collection" />
                    <div data-i18n="Basic">Cards</div>
                </a>
            </li>
            <li className="menu-item">
                <a href="javascript:void(0)" className="menu-link menu-toggle">
                    <i className="menu-icon tf-icons bx bx-box" />
                    <div data-i18n="User interface">User interface</div>
                </a>
                <ul className="menu-sub">
                    <li className="menu-item">
                        <a href="ui-accordion.html" className="menu-link">
                            <div data-i18n="Accordion">Accordion</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-alerts.html" className="menu-link">
                            <div data-i18n="Alerts">Alerts</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-badges.html" className="menu-link">
                            <div data-i18n="Badges">Badges</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-buttons.html" className="menu-link">
                            <div data-i18n="Buttons">Buttons</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-carousel.html" className="menu-link">
                            <div data-i18n="Carousel">Carousel</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-collapse.html" className="menu-link">
                            <div data-i18n="Collapse">Collapse</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-dropdowns.html" className="menu-link">
                            <div data-i18n="Dropdowns">Dropdowns</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-footer.html" className="menu-link">
                            <div data-i18n="Footer">Footer</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-list-groups.html" className="menu-link">
                            <div data-i18n="List Groups">List groups</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-modals.html" className="menu-link">
                            <div data-i18n="Modals">Modals</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-navbar.html" className="menu-link">
                            <div data-i18n="Navbar">Navbar</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-offcanvas.html" className="menu-link">
                            <div data-i18n="Offcanvas">Offcanvas</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-pagination-breadcrumbs.html" className="menu-link">
                            <div data-i18n="Pagination & Breadcrumbs">Pagination &amp; Breadcrumbs</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-progress.html" className="menu-link">
                            <div data-i18n="Progress">Progress</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-spinners.html" className="menu-link">
                            <div data-i18n="Spinners">Spinners</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-tabs-pills.html" className="menu-link">
                            <div data-i18n="Tabs & Pills">Tabs &amp; Pills</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-toasts.html" className="menu-link">
                            <div data-i18n="Toasts">Toasts</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-tooltips-popovers.html" className="menu-link">
                            <div data-i18n="Tooltips & Popovers">Tooltips &amp; popovers</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="ui-typography.html" className="menu-link">
                            <div data-i18n="Typography">Typography</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="menu-item">
                <a href="javascript:void(0)" className="menu-link menu-toggle">
                    <i className="menu-icon tf-icons bx bx-copy" />
                    <div data-i18n="Extended UI">Extended UI</div>
                </a>
                <ul className="menu-sub">
                    <li className="menu-item">
                        <a href="extended-ui-perfect-scrollbar.html" className="menu-link">
                            <div data-i18n="Perfect Scrollbar">Perfect scrollbar</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="extended-ui-text-divider.html" className="menu-link">
                            <div data-i18n="Text Divider">Text Divider</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="menu-item">
                <a href="icons-boxicons.html" className="menu-link">
                    <i className="menu-icon tf-icons bx bx-crown" />
                    <div data-i18n="Boxicons">Boxicons</div>
                </a>
            </li>
            <li className="menu-header small text-uppercase"><span className="menu-header-text">Forms &amp; Tables</span></li>
            
            <li className="menu-item">
                <a href="javascript:void(0);" className="menu-link menu-toggle">
                    <i className="menu-icon tf-icons bx bx-detail" />
                    <div data-i18n="Form Elements">Form Elements</div>
                </a>
                <ul className="menu-sub">
                    <li className="menu-item">
                        <a href="forms-basic-inputs.html" className="menu-link">
                            <div data-i18n="Basic Inputs">Basic Inputs</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="forms-input-groups.html" className="menu-link">
                            <div data-i18n="Input groups">Input groups</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="menu-item">
                <a href="javascript:void(0);" className="menu-link menu-toggle">
                    <i className="menu-icon tf-icons bx bx-detail" />
                    <div data-i18n="Form Layouts">Form Layouts</div>
                </a>
                <ul className="menu-sub">
                    <li className="menu-item">
                        <a href="form-layouts-vertical.html" className="menu-link">
                            <div data-i18n="Vertical Form">Vertical Form</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="form-layouts-horizontal.html" className="menu-link">
                            <div data-i18n="Horizontal Form">Horizontal Form</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="menu-item">
                <a href="tables-basic.html" className="menu-link">
                    <i className="menu-icon tf-icons bx bx-table" />
                    <div data-i18n="Tables">Tables</div>
                </a>
            </li>
            <li className="menu-header small text-uppercase"><span className="menu-header-text">Misc</span></li>
            <li className="menu-item">
                <a href="https://github.com/themeselection/sneat-html-admin-template-free/issues" target="_blank" className="menu-link">
                    <i className="menu-icon tf-icons bx bx-support" />
                    <div data-i18n="Support">Support</div>
                </a>
            </li>
            <li className="menu-item">
                <a href="https://themeselection.com/demo/sneat-bootstrap-html-admin-template/documentation/" target="_blank" className="menu-link">
                    <i className="menu-icon tf-icons bx bx-file" />
                    <div data-i18n="Documentation">Documentation</div>
                </a>
            </li> */}

        </aside>
    );
}

