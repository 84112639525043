import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase';

export default function Login() {
    const [servicos] = useState({
        monitoramento: firebase.firestore().collection('monitoramento'),
    })
    const [servico, setServico] = useState('')
    const [tabela, setTabela] = useState('')
    const [dados, setDados] = useState({})

    useEffect(() => {
        visualizaServicos()
        const dadosJson = JSON.parse(localStorage.getItem('dados'))
        if (dadosJson) {
            setServico(dadosJson.servico)
            setTabela(dadosJson.tabela)
            setDados(dadosJson)
        } else {
            if (!dadosJson) {
                window.location = '/solicitante'
            }
        }
    }, [])

    function visualizaServicos() {
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                visualizacaoServicos: parseInt(res.data().visualizacaoServicos) + parseInt(1),
            })
        })
    }

    function setaServico(serv, tab) {
        setServico(serv)
        setTabela(tab)
    }

    function cadastraDados() {
        if (!servico) {
            mensagemAlerta('Por favor escolha o serviço acima')
            return
        }
        dados.servico = servico
        dados.tabela = tabela
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                confirmacaoServicos: parseInt(res.data().confirmacaoServicos) + parseInt(1),
            }).then(() => {
                localStorage.setItem('dados', JSON.stringify(dados))
                window.location = '/resultado'
            })
        })


    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div class="content-wrapper">

            <div class="container-xxl flex-grow-1 container-p-y">
                <div class="row">
                    <div class="col-xl">
                        <div style={{ padding: 10 }} class="card mb-4">
                            <h1 style={{ marginLeft: 20 }} class="mb-0">Serviços</h1>
                            {/* <img style={{ width: '100%', height: '100%' }} src='/images/servicos.png' /> */}
                        </div>
                    </div>
                    <div class="col-xl">
                        <div class="card mb-4">
                            <div class="card-header align-items-center">
                                <div style={{ textAlign: 'center' }}>
                                    <img src='images/logo.png' />
                                </div>
                                <h4 style={{ marginTop: 20 }} class="mb-0">4. Dados da Nota e serviço</h4>
                            </div>
                            <div class="card-body">
                                <div className='row'>
                                    <div class="mb-3">
                                        <h5 style={{ marginTop: 20 }} class="mb-0">Escolha o serviço abaixo</h5>
                                        <div style={{ marginTop: 20 }} class="row mb-5">
                                            <div class="col-md-12">
                                                <div class="card mb-3">
                                                    <div class="row g-0">
                                                        {/* <div class="col-md-4">
                                                            <img class="card-img card-img-left" src="../assets/img/elements/12.jpg" alt="Card image" />
                                                        </div> */}
                                                        <div class="col-md-8">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Entrega Convencional</h5>
                                                                <p class="card-text">
                                                                    Serviço de entrega confiável e eficiente para satisfazer suas necessidades de transporte de mercadorias.
                                                                </p>
                                                                {servico == '080' && tabela == '132' ?
                                                                    <button onClick={() => setaServico('080', '132')} type="submit" class="btn btn-success">Selecionado</button>
                                                                    :
                                                                    <button onClick={() => setaServico('080', '132')} type="submit" class="btn btn-primary">Selecionar</button>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="col-md-12">
                                                <div class="card mb-3">
                                                    <div class="row g-0">
                                                        <div class="col-md-4">
                                                            <img class="card-img card-img-left" src="../assets/img/elements/12.jpg" alt="Card image" />
                                                        </div>
                                                        <div class="col-md-8">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Rodo Expresso</h5>
                                                                <p class="card-text">
                                                                    Serviço de entrega confiável e eficiente para satisfazer suas necessidades de transporte de mercadorias.
                                                                </p>
                                                                {servico == '081' && tabela == '132' ?
                                                                    <button onClick={() => setaServico('081', '132')} type="submit" class="btn btn-success">Selecionado</button>
                                                                    :
                                                                    <button onClick={() => setaServico('081', '132')} type="submit" class="btn btn-primary">Selecionar</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-12">

                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <a href='/volumes' type="submit" class="btn btn-danger">Voltar</a>
                                    </div>
                                    <div class="mb-3 col-md-3">

                                    </div>
                                    <div class="mb-3 col-md-3">
                                        <button onClick={() => cadastraDados()} type="submit" class="btn btn-primary">Continuar</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-backdrop fade"></div>
        </div>

    );
}