import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { buscaEndereco } from '../../../services/Localidades'
import { postPrazo, login } from '../../../services/Brudam'
import firebase from 'firebase';

export default function OrigemDestino() {
    const [servicos] = useState({
        monitoramento: firebase.firestore().collection('monitoramento'),
    })
    const [dados, setDados] = useState({})

    useEffect(() => {
        visualizaOrigemDestino()
        const dado = JSON.parse(localStorage.getItem('dados'))
        if (dado) {
            setDados(dado)
            if (dado.origem) {
                setCepOrigem(dado.origem.cep)
                setDadosCepOrigem(dado.origem)
                setCepDestino(dado.destino.cep)
                setDadosCepDestino(dado.destino)
                setEntrega(dado.entrega)
                setPagamentoFrete(dado.pagamentoFrete)
            }
        } else {
            if (!dado) {
                window.location = '/solicitante'
            }
        }
    }, [])

    function visualizaOrigemDestino() {
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                visualizacaoOrigemDestino: parseInt(res.data().visualizacaoOrigemDestino) + parseInt(1),
            })
        })
    }

    // Destino
    const [cepOrigem, setCepOrigem] = useState('')
    const [cepDestino, setCepDestino] = useState('')
    const [pagamentoFrete, setPagamentoFrete] = useState('')
    const [entrega, setEntrega] = useState('')
    const [dadosCepOrigem, setDadosCepOrigem] = useState({})
    const [dadosCepDestino, setDadosCepDestino] = useState({})

    function cadastraDados() {
        if (!cepOrigem || cepOrigem.replace(/[^a-z0-9]/gi, '').length < 8) {
            mensagemAlerta('Digite o Cep de origem')
            return
        }
        if (!cepDestino || cepDestino.replace(/[^a-z0-9]/gi, '').length < 8) {
            mensagemAlerta('Digite o Cep de Destino')
            return
        }
        if (cepOrigem === cepDestino) {
            mensagemAlerta('O CEP de origem não pode ser o mesmo do CEP de Destino')
            return
        }
        if (!pagamentoFrete) {
            mensagemAlerta('Por favor selecione quem será o pagador do frete')
            return
        }
        if (!entrega) {
            mensagemAlerta('Escolha como deseja receber a entrega')
            return
        }
        // const prazo = {
        //     "cIbgeOrig": dadosCepOrigem.ibge,
        //     "cIbgeDest": dadosCepDestino.ibge,
        //     "cModal": 1,
        //     "cServ": "080",
        //     // "cepOrig": cepOrigem,
        //     // "cepDest": cepDestino
        // }
        // console.log(prazo)
        // login().then((res) => {
        //     postPrazo(res.data.access_key,prazo).then((resPrazo)=>{
        //         console.log(resPrazo)
        //     })
        // })
        const dadosEnvio = {
            origem: {
                cep: cepOrigem,
                logradouro: dadosCepOrigem.logradouro,
                bairro: dadosCepOrigem.bairro,
                localidade: dadosCepOrigem.localidade,
                uf: dadosCepOrigem.uf,
                ibge: dadosCepOrigem.ibge
            },
            destino: {
                cep: cepDestino,
                logradouro: dadosCepDestino.logradouro,
                bairro: dadosCepDestino.bairro,
                localidade: dadosCepDestino.localidade,
                uf: dadosCepDestino.uf,
                ibge: dadosCepDestino.ibge
            },
            entrega: entrega,
            pagamentoFrete: pagamentoFrete
        }
        dados.origem = dadosEnvio.origem
        dados.destino = dadosEnvio.destino
        dados.entrega = dadosEnvio.entrega
        dados.pagamentoFrete = dadosEnvio.pagamentoFrete

        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                confirmacaoOrigemDestino: parseInt(res.data().confirmacaoOrigemDestino) + parseInt(1),
            }).then(() => {
                localStorage.setItem('dados', JSON.stringify(dados))
                window.location = '/volumes'
            })
        })


    }

    // Endereço
    const handleCepOrigem = (event) => {
        event.persist();
        setDadosCepOrigem({})
        setCepOrigem(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Carregando dados..')
            setDadosCepOrigem({})
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    Swal.close()
                    mensagemAlerta('Endereço não localizado')
                } else {
                    Swal.close()
                    setDadosCepOrigem(dados)
                }
            })
        }
    }
    const handleCepDestino = (event) => {
        event.persist();
        setDadosCepDestino({})
        setCepDestino(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Carregando dados..')
            setDadosCepDestino({})
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    Swal.close()
                    mensagemAlerta('Endereço não localizado')
                } else {
                    Swal.close()
                    setDadosCepDestino(dados)
                }
            })
        }
    }
    const handlePagamentoFrete = (event) => {
        event.persist();
        setPagamentoFrete(event.target.value)
    }
    const handleEntrega = (event) => {
        event.persist();
        setEntrega(event.target.value)
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div class="content-wrapper">

            <div class="container-xxl flex-grow-1 container-p-y">
                <div class="row">
                    <div class="col-xl-4">
                        <div style={{ padding: 10 }} class="card mb-4">
                            <h1 style={{ marginLeft: 20 }} class="mb-0">Origem e Destino</h1>
                            {/* <img style={{ width: '100%', height: '100%' }} src='/images/origemdestino.png' /> */}
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="card mb-4">
                            <div class="card-header align-items-center">
                                <div style={{ textAlign: 'center' }}>
                                    <img src='images/logo.png' />
                                </div>
                                <h4 style={{ marginTop: 20 }} class="mb-0"><b>2. Dados da Origem e do Destino</b></h4>
                            </div>
                            <div class="card-body">
                                <div className='row'>
                                    <div class="mb-3 col-md-12">
                                        <h5 style={{ marginTop: 20 }} class="mb-0">Endereço de Origem</h5>
                                    </div>
                                    <div class="mb-3 col-md-3">
                                        <label class="form-label" for="basic-icon-default-fullname">digite o cep</label>
                                        <div class="input-group input-group-merge">
                                            <input onChange={handleCepOrigem} value={cepOrigem} type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-4">
                                        <label class="form-label" for="basic-icon-default-fullname">Endereço</label>
                                        <div class="input-group input-group-merge">
                                            <input value={dadosCepOrigem.cep ? dadosCepOrigem.logradouro : ''} disabled type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-5">
                                        <label class="form-label" for="basic-icon-default-fullname">Bairro</label>
                                        <div class="input-group input-group-merge">
                                            <input value={dadosCepOrigem.cep ? dadosCepOrigem.bairro : ''} disabled type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-5">
                                        <label class="form-label" for="basic-icon-default-fullname">Cidade</label>
                                        <div class="input-group input-group-merge">
                                            <input value={dadosCepOrigem.cep ? dadosCepOrigem.localidade : ''} disabled type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-2">
                                        <label class="form-label" for="basic-icon-default-fullname">Estado</label>
                                        <div class="input-group input-group-merge">
                                            <input value={dadosCepOrigem.cep ? dadosCepOrigem.uf : ''} disabled type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-12">
                                        <h5 style={{ marginTop: 20 }} class="mb-0">Endereço de Destino</h5>
                                    </div>
                                    <div class="mb-3 col-md-3">
                                        <label class="form-label" for="basic-icon-default-fullname">digite o cep</label>
                                        <div class="input-group input-group-merge">
                                            <input onChange={handleCepDestino} value={cepDestino} type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-4">
                                        <label class="form-label" for="basic-icon-default-fullname">Endereço</label>
                                        <div class="input-group input-group-merge">
                                            <input value={dadosCepDestino.cep ? dadosCepDestino.logradouro : ''} disabled type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-5">
                                        <label class="form-label" for="basic-icon-default-fullname">Bairro</label>
                                        <div class="input-group input-group-merge">
                                            <input value={dadosCepDestino.cep ? dadosCepDestino.bairro : ''} disabled type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-5">
                                        <label class="form-label" for="basic-icon-default-fullname">Cidade</label>
                                        <div class="input-group input-group-merge">
                                            <input value={dadosCepDestino.cep ? dadosCepDestino.localidade : ''} disabled type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-2">
                                        <label class="form-label" for="basic-icon-default-fullname">Estado</label>
                                        <div class="input-group input-group-merge">
                                            <input value={dadosCepDestino.cep ? dadosCepDestino.uf : ''} disabled type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-12">
                                        <h5 style={{ marginTop: 20 }} class="mb-0">Pagamento e Entrega</h5>
                                    </div>
                                    <div class="mb-3 col-md-4">
                                        <label class="form-label" for="basic-icon-default-fullname">Quem pagará o Frete?</label>
                                        <select id="defaultSelect" className="form-select" onChange={handlePagamentoFrete}>
                                            <option>Selecione</option>
                                            <option value={'ORIGEM'} selected={dados && pagamentoFrete === 'ORIGEM' ? 'selected' : null}>Origem</option>
                                            <option value={'DESTINO'} selected={dados && pagamentoFrete === 'DESTINO' ? 'selected' : null}>Destino</option>
                                        </select>
                                    </div>
                                    <div class="mb-3 col-md-4">
                                        <label class="form-label" for="basic-icon-default-fullname">Dados da Entrega</label>
                                        <select id="defaultSelect" className="form-select" onChange={handleEntrega}>
                                            <option>Selecione</option>
                                            <option value={'BASE'} selected={dados && entrega === 'BASE' ? 'selected' : null}>Retira na Base</option>
                                            <option value={'DOMICILIO'} selected={dados && entrega === 'DOMICILIO' ? 'selected' : null}>A domicílio</option>
                                        </select>
                                    </div>
                                    <div class="mb-3 col-md-4">
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <a href='/cotacao' type="submit" class="btn btn-danger">Voltar</a>
                                    </div>
                                    <div class="mb-3 col-md-3">

                                    </div>
                                    <div class="mb-3 col-md-3">
                                        <button onClick={() => cadastraDados()} type="submit" class="btn btn-primary">Continuar</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-backdrop fade"></div>
        </div>

    );
}