import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2'
import CurrencyInputValue from 'react-currency-masked-input'
import CurrencyInput from 'react-currency-input';
import firebase from 'firebase';

export default function Login() {
    const [servicos] = useState({
        monitoramento: firebase.firestore().collection('monitoramento'),
    })
    const [produtos, setProdutos] = useState([])
    const [dados, setDados] = useState({})
    const [cubagem, setCubagem] = useState(0)
    const [pesoCubado, setPesoCubado] = useState(0)
    const refValor = useRef('valor')

    useEffect(() => {
        visualizaVolumes()
        const dadosJson = JSON.parse(localStorage.getItem('dados'))
        if (dadosJson) {
            setDados(dadosJson)
            if (dadosJson.volumes) {
                setProdutos(dadosJson.volumes)
                setValor(dadosJson.valor)
                setDados(dadosJson)
            }
        } else {
            // if(!dadosJson){
            //     window.location = '/solicitante'
            // }
        }
    }, [])

    function visualizaVolumes() {
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                visualizacaoVolumes: parseInt(res.data().visualizacaoVolumes) + parseInt(1),
            })
        })
    }

    // Nome Produto
    const [index, setIndex] = useState(null)
    const [nome, setNome] = useState('')
    const [comprimento, setComprimento] = useState(0)
    const [largura, setLargura] = useState(0)
    const [altura, setAltura] = useState(0)
    const [qtd, setQtd] = useState(0)
    const [peso, setPeso] = useState(0)
    const [valor, setValor] = useState(0)

    function cadastraDados() {
        if (produtos.length == 0) {
            mensagemAlerta('Adicione ao menos um produto')
            return
        }
        if (!valor || valor == 0) {
            mensagemAlerta('Digite o valor da nota')
            return
        }
        dados.volumes = produtos
        dados.valor = parseFloat(valor)
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                confirmacaoVolumes: parseInt(res.data().confirmacaoVolumes) + parseInt(1),
            }).then(() => {
                localStorage.setItem('dados', JSON.stringify(dados))
                window.location = '/servico'
            })
        })
    }

    function adicionaProduto() {
        if (!nome) {
            mensagemAlerta('Informe o nome do produto')
            return
        }
        if (!comprimento) {
            mensagemAlerta('Informe o comprimento do volume')
            return
        }
        if (!largura) {
            mensagemAlerta('Informe a largura do volume')
            return
        }
        if (!altura) {
            mensagemAlerta('Informe a altura do volume')
            return
        }
        if (!qtd) {
            mensagemAlerta('Informe a quantidade de volumes')
            return
        }
        if (!peso) {
            mensagemAlerta('Informe o peso total do volume')
            return
        }
        const list = []
        const dados = {
            nome: nome,
            dAlt: parseFloat(altura),
            dLar: parseFloat(largura),
            dCom: parseFloat(comprimento),
            pBru: parseFloat(peso),
            qVol: parseInt(qtd),
        }
        if (index !== null) {
            produtos[index].nome = nome
            produtos[index].dAlt = parseFloat(altura)
            produtos[index].dLar = parseFloat(largura)
            produtos[index].dCom = parseFloat(comprimento)
            produtos[index].pBru = parseFloat(peso)
            produtos[index].qVol = parseFloat(qtd)
            cancelaAlteracao()
            calculaCubagem()
        } else {
            list.push(
                ...produtos,
                dados
            )
            setNome('')
            setComprimento(0)
            setLargura(0)
            setAltura(0)
            setQtd(0)
            setPeso(0)
            setProdutos(list)
            calculaCubagem()
        }
    }

    function calculaCubagem() {
        let cub = 0
        let pesCub = 0
        produtos.forEach((d) => {
            cub = cub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom))
            pesCub = pesCub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom) * 300) * d.qVol
        })
        console.log(cub)
        setCubagem(parseFloat(cub))
        setPesoCubado(parseFloat(pesCub))
    }

    function removeProduto(p, index) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o produto ' + p.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const list = []
                for (let i = 0; i < produtos.length; i++) {
                    const element = produtos[i];
                    if (i !== index) {
                        list.push(element)
                    }
                }
                cancelaAlteracao()
                setProdutos(list)
            }
        });

    }

    function editaProduto(item, ind) {
        setIndex(ind)
        setComprimento(item.dCom)
        setLargura(item.dLar)
        setAltura(item.dAlt)
        setPeso(item.pBru)
        setQtd(item.qVol)
        setNome(item.nome)
    }

    function cancelaAlteracao() {
        setIndex(null)
        setNome('')
        setComprimento(0)
        setLargura(0)
        setAltura(0)
        setQtd(0)
        setPeso(0)
    }

    // Dados do Produto
    const handleProduto = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    function handleComprimento(event, maskedvalue, floatvalue) {
        setComprimento(maskedvalue);

    }
    function handleLargura(event, maskedvalue, floatvalue) {
        setLargura(maskedvalue);
    }
    function handleAltura(event, maskedvalue, floatvalue) {
        setAltura(maskedvalue);
    }
    const handlePeso = (event) => {
        event.persist();
        setPeso(event.target.value.replace(/[^a-z0-9]/gi, ''));
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value.replace(/[^a-z0-9]/gi, ''));
    }
    function handleValor(event, maskedvalue, floatvalue) {
        setValor(maskedvalue)
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div class="content-wrapper">

            <div class="container-xxl flex-grow-1 container-p-y">
                <div class="row">
                    <div class="col-md-4">
                        <div style={{ padding: 10 }} class="card mb-4">
                            <h1 style={{ marginLeft: 20 }} class="mb-0">Volumes</h1>
                            {/* <img style={{ width: '100%', height: '100%' }} src='/images/volumes.png' /> */}
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-4">
                            <div class="card-header align-items-center">
                                <div style={{ textAlign: 'center' }}>
                                    <img src='images/logo.png' />
                                </div>
                                <h4 style={{ marginTop: 20 }} class="mb-0"><b>3. Dados dos volumes</b></h4>
                                <p style={{ marginTop: 10 }}>Compr: Comprimento em centímetros (cm)<br />Larg: Largura em centímetros (cm)<br />Alt: Altura em centímetros (cm)<br />Peso: Peso em quilos (kg)</p>

                            </div>
                            <div class="card-body">
                                <div className='row'>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label" for="basic-icon-default-fullname">Nome do produto</label>
                                        <div class="input-group input-group-merge">
                                            <input value={nome} onChange={handleProduto} type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-2">
                                        <label class="form-label" for="basic-icon-default-fullname">Compr</label>
                                        <div class="input-group input-group-merge">
                                            <CurrencyInputValue name="comprimento" id='comprimento' className="form-control" value={comprimento} onChange={handleComprimento} decimalSeparator="," thousandSeparator="." />
                                            {/* <input value={comprimento} onChange={handleComprimento} type="number" class="form-control" /> */}
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-2">
                                        <label class="form-label" for="basic-icon-default-fullname">larg</label>
                                        <div class="input-group input-group-merge">
                                            <CurrencyInputValue name="largura" id='largura' className="form-control" value={largura} onChange={handleLargura} decimalSeparator="," thousandSeparator="." />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-2">
                                        <label class="form-label" for="basic-icon-default-fullname">Alt</label>
                                        <div class="input-group input-group-merge">
                                            <CurrencyInputValue id='altura' name="altura" className="form-control" value={altura} onChange={handleAltura} decimalSeparator="," thousandSeparator="." />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-2">
                                        <label class="form-label" for="basic-icon-default-fullname">Peso</label>
                                        <div class="input-group input-group-merge">
                                            <input value={peso} min={0} onChange={handlePeso} type="number" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-2">
                                        <label class="form-label" for="basic-icon-default-fullname">Qtd</label>
                                        <div class="input-group input-group-merge">
                                            <input value={qtd} min={0} onChange={handleQtd} type="number" class="form-control" />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 30 }} class="mb-3 col-md-3">
                                        <button onClick={() => adicionaProduto()} type="submit" class="btn btn-info">{index != null ? 'Atualizar' : 'Adicionar'}</button>
                                    </div>
                                    {index != null ?
                                        <div style={{ marginTop: 30 }} class="mb-3 col-md-3">
                                            <button onClick={() => cancelaAlteracao()} type="submit" class="btn btn-danger">Cancelar</button>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className='mb-3 col-md-12'>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Produto</th>
                                                    <th>medidas(Compr x Larg x Alt)</th>
                                                    <th>Peso</th>
                                                    <th>Qtd</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-border-bottom-0">
                                                {produtos.map((p, index) =>
                                                    <tr>
                                                        <td>{p.nome}</td>
                                                        <td>{parseInt(p.dCom * 100)} cm {parseInt(p.dLar * 100)} cm x {parseInt(p.dAlt * 100)} cm </td>
                                                        <td>{p.pBru} kg</td>
                                                        <td>{p.qVol}</td>
                                                        <td>
                                                            <div class="dropdown">
                                                                <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </button>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" onClick={() => editaProduto(p, index)} href="javascript:void(0);"
                                                                    ><i class="bx bx-edit-alt me-1"></i> Editar</a>
                                                                    <a class="dropdown-item" onClick={() => removeProduto(p, index)} href="javascript:void(0);"
                                                                    ><i class="bx bx-trash me-1"></i> Excluir</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div class="mb-3 col-md-3" style={{marginTop:20}}>
                                        <label class="form-label" for="basic-icon-default-fullname">Cubagem</label>
                                        <p>{cubagem.toFixed(3)} m³</p>
                                    </div>
                                    <div class="mb-3 col-md-3" style={{marginTop:20}}>
                                        <label class="form-label" for="basic-icon-default-fullname">Peso Cubado</label>
                                        <p>{pesoCubado.toFixed(2)} kg</p>
                                    </div> */}
                                    <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                        <label class="form-label" for="basic-icon-default-fullname">Valor da Nota</label>
                                        <div class="input-group input-group-merge">
                                            <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                class="bx bx-money"></i></span>
                                            <CurrencyInputValue className="form-control" prefix="$" value={valor} onChange={handleValor} decimalSeparator="," thousandSeparator="." />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-12">
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <a href='/origemDestino' type="submit" class="btn btn-danger">Voltar</a>
                                    </div>
                                    <div class="mb-3 col-md-4">

                                    </div>
                                    <div class="mb-3 col-md-2">
                                        <button onClick={() => cadastraDados()} type="submit" class="btn btn-primary">Continuar</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-backdrop fade"></div>
        </div>

    );
}