import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { cnpj } from 'cpf-cnpj-validator';
import {getEmpresa} from '../../../services/Agendor'
import InputMask from "react-input-mask";
import firebase from 'firebase';

export default function Login() {
    const [servicos] = useState({
        monitoramento:firebase.firestore().collection('monitoramento'),
    })
    // Dados pessoais
    const [cnpjEmpresa, setCnpjEmpresa] = useState('')
    const [nomeEmpresa, setNomeEmpresa] = useState('')
    const [nomeRepresentante, setNomeRepresentante] = useState('')
    const [email, setEmail] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [dados, setDados] = useState({})
    const [idEmpresa, setIdEmpresa] = useState('')

    useEffect(()=>{
        localStorage.setItem('dados',null)
        visualizaDados()
    },[])

    function cadastraDados(){
        if(!cnpj.isValid(cnpjEmpresa.replace(/[^a-z0-9]/gi, ''))){
            mensagemAlerta('CNPJ inválido')
            return
        }
        if(!nomeEmpresa){
            mensagemAlerta('Digite o nome da Empresa')
            return
        }
        if(!nomeRepresentante){
            mensagemAlerta('Digite o nome do Representante da empresa')
            return
        }
        if(!whatsapp || whatsapp.replace(/[^a-z0-9]/gi, '').length < 11){
            mensagemAlerta('Whatsapp incompleto! Digite o DDD + 9 + Número')
            return
        }
        if(!email || !email.includes('@')){
            mensagemAlerta('Email inválido. Exemplo: email@email.com')
            return
        }
        const dadosEnvio ={
            cnpjEmpresa:cnpjEmpresa,
            nomeEmpresa:nomeEmpresa,
            nomeRepresentante:nomeRepresentante,
            email:email,
            whatsapp:whatsapp
        }
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                confirmacaoDados: parseInt(res.data().confirmacaoDados) + parseInt(1),
            }).then(()=>{
                if(dados){
                    dados.cnpjEmpresa = cnpjEmpresa
                    dados.nomeEmpresa = nomeEmpresa
                    dados.nomeRepresentante = nomeRepresentante
                    dados.email = email
                    dados.whatsapp = whatsapp
                    dados.idEmpresaAgendor = idEmpresa? idEmpresa:null
                    localStorage.setItem('dados',JSON.stringify(dados))
                    window.location = '/origemDestino'
                }else{
                    localStorage.setItem('dados',JSON.stringify(dadosEnvio))
                    window.location = '/origemDestino'
                }
            })
        })
        
    }

    function visualizaDados() {
        servicos.monitoramento.doc('cotacao').get().then((res) => {
            servicos.monitoramento.doc('cotacao').update({
                visualizacaoDados: parseInt(res.data().visualizacaoDados) + parseInt(1),
            })
        })
    }

    // Dados Pessoais
    const handleCnpjEmpresa = (event) => {
        event.persist();
        setCnpjEmpresa(event.target.value);
        if(cnpj.isValid(event.target.value.replace(/[^a-z0-9]/gi, ''))){
            getEmpresa(event.target.value.replace(/[^a-z0-9]/gi, '')).then((res)=>{
                if(res.data.length > 0){
                    setIdEmpresa(res.data[0].id)
                }
            })
        }
    }
    const handleNomeEmpresa = (event) => {
        event.persist();
        setNomeEmpresa(event.target.value);
    }
    const handleNomeRepresentante = (event) => {
        event.persist();
        setNomeRepresentante(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleWhatsapp = (event) => {
        event.persist();
        setWhatsapp(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div class="content-wrapper">

            <div class="container-xxl flex-grow-1 container-p-y">
                <div class="row">
                    <div class="col-xl">
                        <div style={{padding:10}} class="card mb-4">
                            <h1 style={{ marginLeft:20,marginBottom:10 }} class="mb-0">Dados do solicitante</h1>
                            
                            {/* <img style={{ width: '100%', height: '100%' }} src='/images/solicitante.png' /> */}
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="card mb-4">
                            <div class="card-header align-items-center">
                                <div style={{ textAlign: 'center' }}>
                                    <img src='images/logo.png' />
                                </div>
                                <h5 style={{ marginTop: 20 }} class="mb-0">1. Primeiro digite os dados do solicitante</h5>
                                <p style={{ marginTop: 20 }}>Dados básicos para gerar a cotação</p>
                            </div>
                            <div class="card-body">
                                <div className='row'>
                                    <div class="mb-3 col-md-5">
                                        <label class="form-label" for="basic-icon-default-fullname">CNPJ</label>
                                        <div class="input-group input-group-merge">
                                            <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                class="bx bx-user"></i></span>
                                            <InputMask mask="99.999.999/9999-99" onChange={handleCnpjEmpresa} value={cnpjEmpresa} className="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-7">
                                        <label class="form-label" for="basic-icon-default-fullname">Nome da Empresa</label>
                                        <div class="input-group input-group-merge">
                                            <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                class="bx bx-buildings"></i></span>
                                            <input onChange={handleNomeEmpresa} value={nomeEmpresa} type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-7">
                                        <label class="form-label" for="basic-icon-default-fullname">Nome do Representante</label>
                                        <div class="input-group input-group-merge">
                                            <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                class="bx bx-user"></i></span>
                                            <input onChange={handleNomeRepresentante} value={nomeRepresentante} type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-5">
                                        <label class="form-label" for="basic-icon-default-fullname">Whatsapp</label>
                                        <div class="input-group input-group-merge">
                                            <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                class="bx bx-phone"></i></span>
                                            <InputMask mask="(99) 99999-9999" onChange={handleWhatsapp} value={whatsapp} className="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label" for="basic-icon-default-fullname">Email</label>
                                        <div class="input-group input-group-merge">
                                            <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                class="bx bx-envelope"></i></span>
                                            <input onChange={handleEmail} value={email} type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-12">
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        {/* <a href='/cotacao' type="submit" class="btn btn-danger">Voltar</a> */}
                                    </div>
                                    <div class="mb-3 col-md-3">

                                    </div>
                                    <div class="mb-3 col-md-3">
                                        <button onClick={()=> cadastraDados()} type="submit" class="btn btn-primary">Continuar</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-backdrop fade"></div>
        </div>

    );
}